import { useEffect, useState } from "react";
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    Chip,
    Box,
    Typography,
} from "@mui/material";
import agent from "../../app/api/agent";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { ParticipantStatus, Promotion } from "../../app/models/promotion";
import {
    adminTableFirstColumnWidth,
    adminTableFontSize,
    StyledTableCell,
    StyledTableRow,
} from "../../app/style/customStyle";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";

const PromotionPage = () => {
    const [promotion, setPromotions] = useState<Promotion>();
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);

    // Fetch user's current restaurant data on load
    useEffect(() => {
        agent.Promotion.getPromotionById(id!)
            .then((data) => {
                console.log(id);
                setPromotions(data);
            })
            .finally(() => setLoading(false));
    }, [refresh, id]);

    const refreshPage = () => {
        setRefresh(!refresh);
    };

    if (loading) return <LoadingComponent message="Loading..." />;

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box>{/* Create button here */}</Box>

                {/* Display promotion data */}
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a simple table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    fontSize: adminTableFontSize,
                                    fontWeight: "bold",
                                }}
                            >
                                <StyledTableCell
                                    align="center"
                                    width={adminTableFirstColumnWidth}
                                >
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={refreshPage}
                                    >
                                        <RefreshSharpIcon />
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "bold", pl: 2 }}
                                    >
                                        User ID / Email
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Joined Date
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Status
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {promotion!.participants.map((participant, idx) => (
                                <StyledTableRow key={participant.id}>
                                    <StyledTableCell align="center">
                                        {idx + 1}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontWeight: "bold",
                                                pl: 2,
                                            }}
                                        >
                                            {`${participant.user.userName} / `}
                                            {participant.user.email}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {new Date(
                                            participant.createdAt
                                        ).toLocaleDateString()}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {participant.status ===
                                            ParticipantStatus.Joined && (
                                            <Chip
                                                label="Joined"
                                                color="primary"
                                            />
                                        )}
                                        {participant.status ===
                                            ParticipantStatus.Expired && (
                                            <Chip
                                                label="Expired"
                                                color="warning"
                                            />
                                        )}
                                        {participant.status ===
                                            ParticipantStatus.Cancelled && (
                                            <Chip
                                                label="Cancelled"
                                                color="default"
                                            />
                                        )}
                                        {participant.status ===
                                            ParticipantStatus.Completed && (
                                            <Chip
                                                label="Completed"
                                                color="success"
                                            />
                                        )}
                                        {!Object.values(
                                            ParticipantStatus
                                        ).includes(participant.status) && (
                                            <Chip
                                                label="Unknown"
                                                color="default"
                                            />
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default PromotionPage;
