import { Restaurant } from "./restaurant";
import { User } from "./user";


export enum ParticipantStatus {
    Joined = "Joined",
    Expired = "Expired",
    Cancelled = "Cancelled",
    Completed = "Completed",
}

export interface Participant {
    id: string;
    userId: string;
    user: User;
    promotionEventId: string;
    createdAt: string;
    lastModifiedAt: string;
    status: ParticipantStatus;
}

export interface Promotion {
    id: string;
    isActive: boolean;
    title: string;
    description: string;
    thumbnailUrl: string;
    imageUrl: string;
    membershipRequired: boolean;
    maxParticipants: number;
    currentParticipants: number;
    restaurantId: number;
    restaurant: Restaurant;
    startDate: string;
    endDate: string;
    participants: Participant[];
}