import { useEffect, useState } from "react";
import {
    useAppSelector,
} from "../../../app/store/configureStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
    Typography,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Box,
    Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import NoMembershipMessage from "./NoMembershipMessage";
import { Restaurant } from "../../../app/models/restaurant";
import agent from "../../../app/api/agent";

const MembershipCard = () => {
    const { user } = useAppSelector((state) => state.account);

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                await agent.Restaurant.getRestaurants();
            } catch (error) {
                console.error("Error fetching restaurants:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRestaurants();
    }, []);

    const [loading, setLoading] = useState(true);
    const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(
        null
    );

    const handleExpandClick = (index: number) => {
        setExpandedCardIndex((prev) => (prev === index ? null : index));
    };

    if (!user) return <LoadingComponent message="Loading..." />;
    if (loading) return <LoadingComponent message="Fetching restaurants..." />;

    const parseMembershipData = () => {
        if (user!.membershipsDto === undefined) return null;
        return user!.membershipsDto;
    };

    const membershipData = parseMembershipData();

    if (!membershipData) return <>No membership found</>;

    if (membershipData.length === 0)
        return <NoMembershipMessage></NoMembershipMessage>;

    return (
        <Box
            sx={{
                maxWidth: 450,
                margin: "auto",
                padding: "20px",
                backgroundColor: "white",
            }}
        >
            {membershipData.map((membership, index) => (
                <Box key={index} marginBottom={2}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: "#eaeaea" }}>
                                    <img
                                        alt={
                                            membership.membershipRestaurants[0]
                                                .restaurant.restaurantName
                                        }
                                        src={`/images/${membership.membershipRestaurants[0].restaurant.restaurantName}Icon.png`}
                                        width="100%"
                                    />
                                </Avatar>
                            }
                            action={
                                <IconButton
                                    aria-label="expand"
                                    onClick={() => handleExpandClick(index)}
                                    sx={{
                                        transform:
                                            expandedCardIndex === index
                                                ? "rotate(180deg)"
                                                : "rotate(0deg)",
                                        transition: "transform 0.2s",
                                    }}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            title={
                                membership.membershipRestaurants[0].restaurant
                                    .restaurantName
                            }
                            subheader={
                                membership.membershipType +
                                " Membership - " +
                                (membership.membershipRestaurants[0]
                                    .subscriptionStatus
                                    ? "Subscribed"
                                    : "Unsubscribed")
                            }
                        />
                        <Collapse
                            in={expandedCardIndex === index}
                            timeout="auto"
                            unmountOnExit
                        >
                            <CardContent sx={{ marginBottom: -2 }}>
                                <Typography variant="body1">
                                    <strong>Joined At:</strong>{" "}
                                    {new Date(
                                        membership.createdTime
                                    ).toLocaleString()}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "auto",
                                        padding: "1rem",
                                    }}
                                >
                                    <Button
                                        component={NavLink}
                                        to={`/membership?restaurantName=${membership.membershipRestaurants[0].restaurant.restaurantName}&membershipType=${membership.membershipType}`}
                                    >
                                        View Details
                                    </Button>
                                </Box>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Box>
            ))}

            {/* This shouldn't be visible */}
            {/* {unjoinedRestaurants.length > 0 && (
                <Typography variant="body1" align="center" marginTop={10}>
                    Please visit the following restaurants to join memberships
                </Typography>
            )}

            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                {unjoinedRestaurants.map((restaurant) => (
                    <Grid
                        item
                        xs={12}
                        key={restaurant.id}
                        sx={{ maxWidth: "450px" }}
                    >
                        <MembershipRestaurantList restaurant={restaurant} />
                    </Grid>
                ))}
            </Grid> */}
        </Box>
    );
};

export default MembershipCard;
