import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    Typography,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useState } from "react";
import TermsOfService from "../../configuration/TermsOfService";
import PrivacyPolicy from "../../configuration/PrivacyPolicy";
import Pricing from "../../configuration/Pricing";

export default function HomeFooter() {
    const [openTerms, setOpenTerms] = useState(false);
    const [openPrivacy, setOpenPrivacy] = useState(false);
    const [openPricing, setOpenPricing] = useState(false);

    const handleClickOpenTerms = () => {
        setOpenTerms(true);
    };

    const handleClickOpenPrivacy = () => {
        setOpenPrivacy(true);
    };

    const handleClose = () => {
        setOpenTerms(false);
        setOpenPrivacy(false);
        setOpenPricing(false);
    };

    const handleClickOpenPricing = () => {
        setOpenPricing(true);
    };

    return (
        <List>
            <Grid
                container
                textAlign="center"
                fontSize="1.2rem"
                color="black"
                fontWeight={700}
                lineHeight="2rem"
                maxWidth="1000px"
                margin="auto"
            >
                <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                        marginTop: { xs: "2rem", sm: 0 },
                        marginBottom: { xs: "2rem", sm: "1rem" },
                    }}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: { sm: "80px" } }}
                    >
                        <Typography
                            variant="h6"
                            fontWeight="inherit"
                            sx={{ cursor: "pointer", marginBottom: "1rem" }}
                        >
                            About Us
                        </Typography>
                    </Grid>
                    <Typography
                        variant="body2"
                        onClick={handleClickOpenTerms}
                        sx={{ cursor: "pointer", marginBottom: "1rem" }}
                    >
                        Terms of Service
                    </Typography>
                    <Dialog
                        open={openTerms}
                        onClose={handleClose}
                        maxWidth="md"
                        fullWidth={true}
                        sx={{ cursor: "pointer" }}
                    >
                        <DialogTitle>Terms of Service</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <TermsOfService />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Typography
                        variant="body2"
                        onClick={handleClickOpenPrivacy}
                        sx={{ cursor: "pointer", marginBottom: "1rem" }}
                    >
                        Privacy Policy
                    </Typography>

                    <Dialog
                        open={openPrivacy}
                        onClose={handleClose}
                        maxWidth="md"
                        fullWidth={true}
                    >
                        <DialogTitle>Privacy Policy</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <PrivacyPolicy />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Typography
                        variant="body2"
                        sx={{ cursor: "pointer", marginBottom: "1rem" }}
                    >
                        <a
                            href="https://www.linkedin.com/company/queuingme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            Contact Us
                        </a>
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{ marginBottom: { xs: "3rem", sm: 0 } }}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: { sm: "80px" } }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ cursor: "pointer", marginBottom: "1rem" }}
                            fontWeight="inherit"
                        >
                            Product
                        </Typography>
                    </Grid>
                    <Typography
                        variant="body2"
                        onClick={handleClickOpenPricing}
                        sx={{ cursor: "pointer" }}
                    >
                        Pricing
                    </Typography>
                    <Dialog open={openPricing} onClose={handleClose}>
                        <DialogTitle>Pricing</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Pricing />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        mb={5}
                        sx={{ height: { sm: "80px" } }}
                    >
                        <Typography variant="inherit">
                            <a
                                href="https://www.linkedin.com/company/queuingme"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkedInIcon
                                    color="primary"
                                    fontSize="large"
                                />
                            </a>
                            <a
                                href="https://www.queuingme.com/login"
                                rel="noopener noreferrer"
                            >
                                <LoginIcon color="primary" fontSize="large" />
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </List>
    );
}
