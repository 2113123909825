import { useEffect, useState } from "react";
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Grid,
    Chip,
    Box,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import agent from "../../app/api/agent";
import { Promotion } from "../../app/models/promotion";
import LoadingComponent from "../../app/layout/LoadingComponent";

const PromotionCardPage = () => {
    const [promotions, setPromotions] = useState<Promotion[]>([]);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        agent.Promotion.getAllPromotions(restaurantParam!)
            .then((data) => {
                setPromotions(data);
            })
            .finally(() => setLoading(false));
    }, [restaurantParam]);

    const handleJoinPromotion = (promotionId: string) => {
        agent.Promotion.joinPromotion(promotionId)
            .then(() => {
                alert("Successfully joined the promotion!");
            })
            .catch((error) => {
                console.error(error);
                alert("Failed to join the promotion.");
            });
    };

    const handleViewDetails = (promotionId: string) => {
        console.log(`/${restaurantParam}/promotions/${promotionId}`);
        navigate(`/${restaurantParam}/promotions/${promotionId}`);
    };

    if (loading) return <LoadingComponent message="Loading promotions..." />;

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={3}>
                {promotions && promotions.map((promotion) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={promotion.id}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Card
                            sx={{
                                maxWidth: 345,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="140"
                                image={promotion.thumbnailUrl}
                                alt={promotion.title}
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    {promotion.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {promotion.description}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 2,
                                    }}
                                >
                                    <Typography variant="body2">
                                        Participants:{" "}
                                        {promotion.currentParticipants} /{" "}
                                        {promotion.maxParticipants}
                                    </Typography>
                                    {promotion.membershipRequired && (
                                        <Chip
                                            label="Membership Required"
                                            size="small"
                                            color="warning"
                                        />
                                    )}
                                </Box>
                                <Typography
                                    variant="body2"
                                    sx={{ mt: 1 }}
                                >
                                    {new Date(
                                        promotion.startDate
                                    ).toLocaleDateString()}{" "}
                                    -{" "}
                                    {new Date(
                                        promotion.endDate
                                    ).toLocaleDateString()}
                                </Typography>
                            </CardContent>
                            <CardActions
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        handleJoinPromotion(promotion.id)
                                    }
                                >
                                    Join
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() =>
                                        handleViewDetails(promotion.id)
                                    }
                                >
                                    View Details
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PromotionCardPage;
