import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Paper,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import agent from "../../app/api/agent";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { phoneNumberConvertor } from "../../app/configuration/phoneNumberConvertor";
import TermsOfServiceRegistration from "../../app/configuration/TermsOfServiceRegistration";
import PrivacyPolicy from "../../app/configuration/PrivacyPolicy";
import { toast } from "react-toastify";

export default function Register() {
    const {
        register,
        handleSubmit,
        setError,
        getValues,
        watch,
        formState: { isSubmitting, errors, isValid },
    } = useForm({
        mode: "all",
    });
    const navigate = useNavigate();
    const [phone, setPhone] = useState("+1");

    const [searchParams] = useSearchParams();
    const restaurantName =
        searchParams.get("restaurantName") || "Unknown Restaurant";
    const membershipType = searchParams.get("membershiptype") || "Basic";
    const [openTerms, setOpenTerms] = useState(false);
    const handleTermsOpen = () => setOpenTerms(true);
    const handleTermsClose = () => setOpenTerms(false);
    const [openPrivacy, setOpenPrivacy] = useState(false);
    const handlePrivacyOpen = () => setOpenPrivacy(true);
    const handlePrivacyClose = () => setOpenPrivacy(false);

    function handlerApiErrors(errors: any) {
        if (errors) {
            errors.forEach((error: string) => {
                if (error.includes("Password")) {
                    setError("password", { message: error });
                } else if (error.includes("Email")) {
                    setError("email", { message: error });
                } else if (error.includes("Username")) {
                    setError("username", { message: error });
                }
            });
        }
        console.log(errors);
    }

    const handleChange = (newPhone: string) => {
        setPhone(() => {
            if (newPhone.length <= 2) return "+1";
            return newPhone;
        });
    };

    return (
        <Container
            component={Paper}
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto",
                mt: 1,
                mb: 4,
                p: 2,
                boxShadow: 10,
                width: "95%",
            }}
        >
            <img
                src={`/images/QueuingMeLogo.png`}
                width="100px"
                style={{ margin: "auto" }}
                alt="logo"
            />
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
            </Avatar>
            {restaurantName !== "Unknown Restaurant" && (
                <>
                    <Typography variant="body1">
                        Already a member?
                    </Typography>
                    <Button
                        variant="text"
                        sx={{ mb: 4 }}
                        onClick={() => navigate(`/login`)}
                        size="large"
                    >
                        Sign in
                    </Button>
                </>
            )}

            <Typography variant="h6">
                {restaurantName !== "Unknown Restaurant" &&
                    restaurantName + " Membership "}
                Sign-Up
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit((data) => {
                    data.phoneNumber = phoneNumberConvertor(data.phoneNumber);
                    agent.Account.register(data)
                        .then(() => {
                            console.log("restaurantName: ", restaurantName);
                            toast.success("Registration successful");
                            alert("Welcome to QueuingMe, " + getValues("firstName"));
                            if (restaurantName === "Unknown Restaurant") {
                                navigate(`/login`);
                            } else {
                                navigate(
                                    `/login?restaurantName=${restaurantName}&membershipType=${membershipType}`
                                );
                            }
                        })
                        .catch((error) => handlerApiErrors(error));
                })}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="User name(ID)"
                    autoComplete="userId"
                    autoFocus
                    {...register("username", {
                        required: "Username is required",
                        minLength: {
                            value: 4,
                            message:
                                "Username must be at least 4 characters long",
                        },
                    })}
                    error={!!errors.username}
                    helperText={errors?.username?.message?.toString()}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Password *"
                    type="password"
                    autoComplete="current-password"
                    {...register("password", {
                        required: "Password is required",
                        pattern: {
                            value: /(?=^.{6,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message:
                                "Password expects atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 6-16 characters.",
                        },
                    })}
                    error={!!errors.password}
                    helperText={errors?.password?.message?.toString()}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Confirm Password *"
                    type="password"
                    {...register("confirmPassword", {
                        required: "Please confirm your password",
                        validate: (value) =>
                            value === watch("password") ||
                            "Passwords do not match",
                    })}
                    error={!!errors.confirmPassword}
                    helperText={errors?.confirmPassword?.message?.toString()}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Email address"
                    autoComplete="email"
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                            value: /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
                            message: "Not a valid email address",
                        },
                    })}
                    error={!!errors.email}
                    helperText={errors?.email?.message?.toString()}
                />
                <MuiTelInput
                    required
                    fullWidth
                    id="outlined-number"
                    label="Phone number"
                    defaultCountry="CA"
                    autoComplete="do-not-autofill"
                    margin="normal"
                    {...register("phoneNumber", {
                        required: "Phone is required",
                        pattern: {
                            value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, //eslint-disable-line
                            message: "Valid format: +1 604 774 7985",
                        },
                    })}
                    onChange={handleChange}
                    value={phone}
                    onlyCountries={["CA", "US"]}
                    error={!!errors.phoneNumber}
                    helperText={errors?.phoneNumber?.message?.toString()}
                />

                <Grid container spacing={{ xs: 0, sm: 2 }}>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="First Name"
                            {...register("firstName", {
                                required: "First Name is required",
                            })}
                            error={!!errors.firstName}
                            helperText={errors?.firstName?.message?.toString()}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Last Name"
                            {...register("lastName", {
                                required: "Last Name is required",
                            })}
                            error={!!errors.lastName}
                            helperText={errors?.lastName?.message?.toString()}
                        />
                    </Grid>
                </Grid>
                {/* Terms and Conditions */}
                <FormControlLabel
                    control={
                        <Checkbox
                            {...register("terms", {
                                required: "You must agree to the terms",
                            })}
                        />
                    }
                    label={
                        <>
                            I agree to the{" "}
                            <Typography
                                variant="body1"
                                component="span"
                                color="primary"
                                onClick={handleTermsOpen}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                            >
                                Terms
                            </Typography>{" "}
                            and{" "}
                            <Typography
                                variant="body1"
                                component="span"
                                color="primary"
                                onClick={handlePrivacyOpen}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                            >
                                Privacy Policy
                            </Typography>
                            .
                        </>
                    }
                    sx={{ mt: 1}}
                />
                {errors.terms && (
                    <Typography color="error" variant="body2">
                        {!!errors.terms.message}
                    </Typography>
                )}
                <FormControlLabel
                    control={<Checkbox {...register("IsSubscribed")} />}
                    label="I want to receive updates about products, promotions, and membership benefits via email or text message."
                    sx={{ mt: 2}}
                />

                <LoadingButton
                    loading={isSubmitting}
                    disabled={!isValid}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                >
                    Register
                </LoadingButton>
                <Grid container justifyContent="center" alignItems="center" mt={4}>
                    <Grid item>
                        <Typography
                            fontSize={16}
                            textAlign="center"
                            sx={{
                                display: "inline-flex",
                                alignItems: "center",
                            }}
                        >
                            Already have an account?
                            <Link
                                to={`/login`}
                                style={{
                                    padding: 4,
                                    fontWeight: 500,
                                    fontSize: 18,
                                }}
                            >
                                Sign In
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {/* Terms Dialog */}
            <Dialog
                open={openTerms}
                onClose={handleTermsClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Terms of Service</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TermsOfServiceRegistration />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTermsClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {/* Terms Dialog */}
            <Dialog
                open={openPrivacy}
                onClose={handlePrivacyClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Privacy Policy</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <PrivacyPolicy />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePrivacyClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
