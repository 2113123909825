import {
    Container,
    Grid,
    Typography,
    Divider,
    Link,
    Button,
    Box,
} from "@mui/material";
import { primaryColor } from "../../../app/style/customColor";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../app/store/configureStore";
import { displayPhoneNumber } from "../../../app/configuration/phoneNumberConvertor";
import PlaceIcon from "@mui/icons-material/Place";
import { useEffect, useState } from "react";
import agent from "../../../app/api/agent";
import { fetchCurrentUser } from "../../account/accountSlice";
import MenuUploadDialog from "./MenuUploadDialog";
import { Restaurant, RestaurantUrlDto } from "../../../app/models/restaurant";
import DynamicDialog from "../../../app/layout/partComponents/DynamicDialog";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import InstagramIcon from "@mui/icons-material/Instagram";

//TODO: Implement Address Controller to update restaurant address or phone number, address should use google map api to get the address
export default function RestaurantInfo() {
    const { user } = useAppSelector((state) => state.account);
    const dispatch = useAppDispatch();
    const [selectedRestaurant, setSelectedRestaurant] =
        useState<Restaurant | null>(null);
    const [openImageModal, setOpenImageModal] = useState<{
        [key: string]: boolean;
    }>({});
    const [dialogOpen, setDialogOpen] = useState<{
        [key: string]: boolean;
    }>({});
    const [formData, setFormData] = useState<RestaurantUrlDto>({
        facebook: "",
        instagram: "",
        website: "",
    });

    const [isUpdated, setIsUpdated] = useState(false); // Toggle to update the component
    useEffect(() => {
        dispatch(fetchCurrentUser());
    }, [dispatch, isUpdated]);

    if (!user) return null;

    const handleOpenImageModal = (restaurantName: string) => {
        setOpenImageModal((prevState) => ({
            ...prevState,
            [restaurantName]: true,
        }));
    };

    const handleCloseImageModal = (restaurantName: string) => {
        setOpenImageModal((prevState) => ({
            ...prevState,
            [restaurantName]: false,
        }));
    };

    const handleRequest = async (row: Restaurant, file: File) => {
        setSelectedRestaurant(row);
        await agent.Restaurant.updateMenuURL(row.restaurantName, file);
        setIsUpdated(!isUpdated);
    };

    const handleOpenDialog = (restaurantName: string) => {
        setDialogOpen((prevState) => ({
            ...prevState,
            [restaurantName]: true,
        }));
    };

    const handleCloseDialog = (restaurantName: string) => {
        setDialogOpen((prevState) => ({
            ...prevState,
            [restaurantName]: false,
        }));
    };

    const handleChange = (key: string, value: string): void => {
        setFormData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleSubmit = async () => {
        if (selectedRestaurant) {
            await agent.Restaurant.updateUrls(
                selectedRestaurant.restaurantName,
                formData
            );
        }
        setIsUpdated(!isUpdated);
    };

    return (
        <Container>
            <Grid container marginTop={3}>
                <RestaurantIcon fontSize="large" color="primary" />
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={primaryColor}
                    sx={{ marginLeft: 1 }}
                >
                    Restaurant
                </Typography>
            </Grid>
            <Divider />
            {user?.restaurants?.map((restaurant, idx) => {
                return (
                    <Box key={idx} mb={10}>
                        <Grid
                            container
                            spacing={2}
                            key={restaurant.id}
                            margin={2}
                        >
                            <Grid
                                item
                                xs={12}
                                md={2}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <img
                                    src={`/images/${restaurant.restaurantName}Icon.png`}
                                    style={{
                                        width: "100px",
                                    }}
                                    alt="logo"
                                />
                            </Grid>

                            <Grid item xs={12} md={10}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1.5,
                                            }}
                                        >
                                            {restaurant.restaurantName}{" "}
                                            <Link
                                                component="button"
                                                color="inherit"
                                                href={
                                                    "tel:" +
                                                    restaurant.phoneNumber
                                                }
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    gap: 0.5,
                                                }}
                                            >
                                                <PlaceIcon
                                                    sx={{ fontSize: "large" }}
                                                />
                                                <Typography variant="body2">
                                                    {displayPhoneNumber(
                                                        restaurant.phoneNumber
                                                    )}
                                                </Typography>
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Link
                                                color="inherit"
                                                href={
                                                    "https://www.google.com/maps/search/?api=1&query=" +
                                                    restaurant.restaurantName
                                                }
                                                target="blank"
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    gap: 0.5,
                                                }}
                                            >
                                                <PlaceIcon
                                                    sx={{
                                                        fontSize: "large",
                                                        verticalAlign: "middle",
                                                    }}
                                                />
                                                {`${restaurant.address?.street}, 
                                        ${restaurant.address?.city}, 
                                        ${restaurant.address?.province} 
                                        ${restaurant.address?.postalCode}`}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setSelectedRestaurant(
                                                    restaurant
                                                );
                                                handleOpenImageModal(
                                                    restaurant.restaurantName
                                                );
                                            }}
                                            startIcon={<RestaurantMenuIcon />}
                                        >
                                            Upload Menu
                                        </Button>
                                        <MenuUploadDialog
                                            open={
                                                openImageModal[
                                                    restaurant.restaurantName
                                                ] || false
                                            }
                                            onClose={() => {
                                                handleCloseImageModal(
                                                    restaurant.restaurantName
                                                );
                                            }}
                                            currentMenuUrl={
                                                restaurant.restaurantOption
                                                    ?.menuImageUrl ?? ""
                                            }
                                            onUpload={async (file) => {
                                                if (selectedRestaurant) {
                                                    try {
                                                        await handleRequest(
                                                            selectedRestaurant,
                                                            file
                                                        );
                                                        alert(
                                                            "Menu image uploaded successfully!"
                                                        );
                                                    } catch (error) {
                                                        console.error(
                                                            "Error uploading menu image:",
                                                            error
                                                        );
                                                        alert(
                                                            "Failed to upload menu image. Please try again."
                                                        );
                                                    }
                                                }
                                            }}
                                        />

                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{ marginLeft: 1 }}
                                            onClick={() => {
                                                setSelectedRestaurant(
                                                    restaurant
                                                );
                                                setFormData({
                                                    facebook:
                                                        restaurant!
                                                            .restaurantOption
                                                            ?.facebookUrl ?? "",
                                                    instagram:
                                                        restaurant!
                                                            .restaurantOption
                                                            ?.instagramUrl ??
                                                        "",
                                                    website:
                                                        restaurant!
                                                            .restaurantOption
                                                            ?.websiteUrl ?? "",
                                                });
                                                handleOpenDialog(
                                                    restaurant!.restaurantName
                                                );
                                            }}
                                            startIcon={<InstagramIcon />}
                                        >
                                            Update Website Address
                                        </Button>
                                        <DynamicDialog
                                            open={
                                                dialogOpen[
                                                    restaurant.restaurantName
                                                ] || false
                                            }
                                            title="Update URLs"
                                            data={Object.fromEntries(
                                                Object.entries(formData).map(
                                                    ([key, value]) => [
                                                        key,
                                                        value,
                                                    ]
                                                )
                                            )}
                                            message="Please enter the URLs for Facebook, Instagram, and Website. Leave blank if you don't have any."
                                            onClose={() =>
                                                handleCloseDialog(
                                                    selectedRestaurant!
                                                        .restaurantName
                                                )
                                            }
                                            onSubmit={handleSubmit}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Divider />
                    </Box>
                );
            })}
        </Container>
    );
}
