import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Register from "../../features/account/Register";
import AdminPage from "../../features/admin/AdminPage";
import DisplayPage from "../../features/guest/Queue/DisplayPage";
import GuestQueuePage from "../../features/guest/Queue/GuestQueuePage";
import Login from "../../features/account/Login";
import RequireAuth from "./RequireAuth";
import UpdateQueuePage from "../../features/guest/Queue/UpdateQueuePage";
import HistoryPage from "../../features/admin/HistoryPage";
import GuestQueueDetailsPage from "../../features/guest/Queue/GuestQueueDetailsPage";
import ServerError from "../../features/errors/ServerError";
import NotFound from "../../features/errors/NotFound";
import MainPage from "../../features/restaurant/MainPage";
import DisplayPageAPI from "../../features/guest/Queue/DisplayPageAPI";
import DisplayPageAPIMargin from "../../features/guest/Queue/DisplayPageAPI";
import DisplayPageAPIChromeCast from "../../features/guest/Queue/DisplayPageAPIChromeCast";
import Home from "./HomePage/Home";
import RequireParam from "./RequireParam";
import ProfilePage from "../../features/admin/AccountSetting/ProfilePage";
import StatisticsPage from "../../features/admin/StatisticsPage";
import PasswordResetPage from "../../features/admin/AccountSetting/PasswordResetPage";
import DisplayActiveQueues from "../../features/admin/DisplayActiveQueues";
import TechnologyPage from "./HomePage/TechnologyPage";
import { publicRoutes } from "../configuration/globalConfig";
import PromotionPage from "../../features/admin/PromotionPage";
import MembershipPage from "../../features/admin/MembershipPage";
import PromotionCardPage from "../../features/admin/PromotionCardPage";
import MembershipDetailsPage from "../../features/guest/Membership/MembershipDetailsPage";
import MembershipIdQRScan from "../../features/guest/Membership/MembershipIdQRScan";
import PasswordResetFormPublic from "../../features/public/account/PasswordResetFormPublic";

export const router = createBrowserRouter([
    {
        path: `/:restaurantParam`,
        element: <App />,
        // errorElement: <ErrorPage />,
        children: [
            //admin routes
            {
                element: <RequireAuth roles={["Admin", "SuperAdmin"]} />,
                children: [
                    { path: "admin", element: <AdminPage /> },
                    { path: "guest/:id", element: <UpdateQueuePage /> },
                    { path: "history", element: <HistoryPage /> },
                    // { path: "calendar", element: <CalendarPage /> },
                    { path: "membership", element: <MembershipPage /> },
                    { path: "promotion", element: <PromotionCardPage /> },
                    { path: "promotions/:id", element: <PromotionPage /> },
                    { path: "statistics", element: <StatisticsPage /> },
                    { path: "profilePage", element: <ProfilePage /> },
                    { path: "passwordReset", element: <PasswordResetPage /> },
                    { path: "displayAll", element: <DisplayActiveQueues /> },
                    { path: "guest", element: <GuestQueuePage /> },
                    { path: "display", element: <DisplayPage /> },
                    { path: "", element: <TechnologyPage /> },
                ],
            },
            //public routes, restaurant parameter check
            {
                element: <RequireParam />,
                children: [
                    { path: "guest", element: <GuestQueuePage /> },
                    { path: "display", element: <DisplayPage /> },
                    // { path: "displayAPI", element: <DisplayPageAPI /> },
                ],
            },
            //public routes
            // { path: "guest", element: <GuestQueuePage /> },
            // { path: "display", element: <DisplayPage /> },
            // { path: "displayAPI", element: <DisplayPageAPI /> },
            // { path: "login", element: <Login /> },
            // { path: "register", element: <Register /> },
            // { path: "error", element: <ErrorTestPage /> },
            // { path: "server-error", element: <ServerError /> },
            // { path: "main", element: <MainPage /> },
            // { path: "membership", element: <MembershipDetailsPage /> },
            // { path: "not-found", element: <NotFound /> },
            // {
            //     path: "*",
            //     element: <Navigate replace to={"/not-found"} />,
            // },
        ],
    },
    //public routes without header
    {
        path: `:restaurantParam/${publicRoutes}`,
        children: [
            {
                path: "guest",
                element: <GuestQueuePage />,
            },
            {
                path: "guest/:id",
                element: <GuestQueueDetailsPage />,
            },
            {
                path: "display",
                element: <DisplayPage />,
            },
            {
                path: "displayAPI",
                element: <DisplayPageAPI />,
            },
            {
                path: "displayAPIChromeCast",
                element: <DisplayPageAPIChromeCast />,
            },
            {
                path: "displayAPIMargin",
                element: <DisplayPageAPIMargin />,
            },
        ],
    },
    { path: "checkMembership", element: <MembershipIdQRScan /> },
    { path: "membership", element: <MembershipDetailsPage /> },
    { path: "main", element: <MainPage /> },
    { path: "server-error", element: <ServerError /> },
    { path: "login", element: <Login /> },
    { path: "register", element: <Register /> },
    { path: "passwordReset", element: <PasswordResetPage /> },
    { path: "request-password", element: <PasswordResetFormPublic /> },
    { path: "not-found", element: <NotFound /> },
    // { path: "calendar", element: <Calendar /> },
    // { path: "calendar3", element: <Calendar3 /> },
    // onDateTimeSelected: (selectedDateTime: DateTime) => void;
    // {
    //     path: "calendar4",
    //     element: (
    //         <Calendar4
    //             label="Select a date and time"
    //             onDateTimeSelected={handleDateTimeSelected}
    //         />
    //     ),
    // },
    { path: "*", element: <Login /> },
    { path: "/", element: <Home /> },
]);