import {
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import PasswordResetForm from "./PasswordResetForm";
import Profile from "./Profile";
import RestaurantInfo from "./RestaurantInfo";
import Application from "./Application";
import Support from "./Support";
import TroubleShoot from "./TroubleShoot";
import MembershipInfo from "./MembershipInfo";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { useState } from "react";

export default function ProfilePage() {
    const [selectedLabel, setSelectedLabel] = useState("Profile");

    const handleItemClick = (label: string) => {
        setSelectedLabel(label);
    };

    const renderComponent = () => {
        switch (selectedLabel) {
            case "Profile":
                return <Profile />;
            case "Password Change":
                return <PasswordResetForm />;
            case "RestaurantInfo":
                return <RestaurantInfo />;
            case "MembershipInfo":
                return <MembershipInfo />;
            case "Application":
                return <Application />;
            case "Trouble Shoot":
                return <TroubleShoot />;
            case "Support":
                return <Support />;
            default:
                return <Profile />;
        }
    };

    return (
        <Paper>
            <Container sx={{ p: 2, minHeight: "600px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                        <nav aria-label="main mailbox folders">
                            <List>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("Profile")}
                                >
                                    <ListItemButton
                                        selected={selectedLabel === "Profile"}
                                    >
                                        <PermIdentityIcon />
                                        <ListItemText
                                            primary="Profile"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("Password Change")}
                                >
                                    <ListItemButton
                                        selected={selectedLabel === "Password Change"}
                                    >
                                        <PasswordIcon />
                                        <ListItemText
                                            primary="Password Change"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("RestaurantInfo")}
                                >
                                    <ListItemButton
                                        selected={selectedLabel === "RestaurantInfo"}
                                    >
                                        <RestaurantIcon />
                                        <ListItemText
                                            primary="Restaurant"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("MembershipInfo")}
                                >
                                    <ListItemButton
                                        selected={selectedLabel === "MembershipInfo"}
                                    >
                                        <CardMembershipIcon />
                                        <ListItemText
                                            primary="Membership"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    disablePadding
                                    onClick={() =>
                                        handleItemClick("Application")
                                    }
                                >
                                    <ListItemButton
                                        selected={selectedLabel === "Application"}
                                    >
                                        <AutoStoriesIcon />
                                        <ListItemText
                                            primary="Application"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() =>
                                        handleItemClick("Trouble Shoot")
                                    }
                                >
                                    <ListItemButton
                                        selected={selectedLabel === "Trouble Shoot"}
                                    >
                                        <TroubleshootIcon />
                                        <ListItemText
                                            primary="Trouble Shoot"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() =>
                                        handleItemClick("Support")
                                    }
                                >
                                    <ListItemButton
                                        selected={selectedLabel === "Support"}
                                    >
                                        <SupportAgentIcon />
                                        <ListItemText
                                            primary="Support"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </nav>
                    </Grid>
                    <Grid item xs={10} sm={9}>
                        {renderComponent()}
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}