import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    Typography,
} from "@mui/material";

interface DynamicDialogProps {
    open: boolean;
    title: string;
    data: Record<string, string>;
    message: string;
    onClose: () => void;
    onSubmit: () => void;
    onChange: (key: string, value: string) => void;
}

function DynamicDialog({
    open,
    title,
    data,
    message,
    onClose,
    onSubmit,
    onChange,
}: DynamicDialogProps) {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        pt: 2,
                    }}
                >
                    {Object.entries(data).map(([key, value]) => (
                        <TextField
                            key={key}
                            label={key.slice(0, 1).toUpperCase() + key.slice(1)}
                            value={value}
                            onChange={(e) => onChange(key, e.target.value)}
                            fullWidth
                        />
                    ))}
                </Box>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 2 }}
                >
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DynamicDialog;
