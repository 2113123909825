import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    Paper,
    Typography,
} from "@mui/material";
import ListContext from "./ListContext";
import QueueIcon from "@mui/icons-material/Queue";
import SendIcon from "@mui/icons-material/Send";

export default function CompanyPage({ handleButtonClick }: CompanyPageProps) {
    const advantageData = [
        {
            title: "Key Advantages of QueuingMe",
            context: `<li>Proven Results: QueuingMe has already transformed waiting experiences at restaurants in Greater Vancouver, resulting in higher customer satisfaction and return rates.</li>
      <li>Seamless Integration: Integrate QueuingMe effortlessly into your own devices for a smooth transition.</li>
      <li>Dedicated Support: We are committed to helping you every step of the way, ensuring a successful experience.</li>`,
        },
        {
            title: "About QueuingMe",
            context: `<li>Optimized Waitlist Management: Streamline your waitlist management with the intuitive digital platform, providing real-time updates and accurate wait time estimates.</li>
      <li>Enhanced Guest Experience: Keep your guests informed and engaged with transparent wait time notifications through SMS and a web application.</li>
      <li>Improved Operational Efficiency: Maximize table turnover, reduce no-shows, and enhance your restaurant's profitability.</li>`,
        },
    ];

    return (
        <Grid container padding={0}>
            <Grid item xs={12} sx={{ backgroundColor: "WhiteSmoke" }}>
                <Paper
                    elevation={0}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "200px",
                        padding: {
                            xs: "0.5rem",
                            md: "1rem",
                            lg: "2rem",
                        },
                        borderRadius: "2rem",
                        background: `url('/images/QueuingMeLogoWhite.png') no-repeat right top`,
                        backgroundSize: "30vh",
                    }}
                >
                    <Grid container padding={0}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="body1"
                                color="grey"
                                fontWeight={500}
                            >
                                <QueueIcon
                                    sx={{
                                        marginRight: "0.3rem",
                                        fontSize: "1rem",
                                    }}
                                />
                                Waitlist management software
                            </Typography>
                            <Typography
                                variant="h1"
                                gutterBottom
                                sx={{
                                    fontWeight: "700",
                                    fontSize: { xs: "1.7rem", md: "2rem" },
                                    textRendering: "optimizeLegibility",
                                    lineHeight: "2rem",
                                    marginTop: "1rem",
                                    paddingTop: 3,
                                }}
                            >
                                Welcome to QueuingMe!
                            </Typography>
                            <List
                                sx={{
                                    fontWeight: 500,
                                    marginTop: 0,
                                    marginBottom: { xs: 0, md: "3rem" },
                                    fontSize: {
                                        xs: "1rem",
                                        sm: "1.1rem",
                                        md: "1.2rem",
                                    },
                                    "& .MuiListItem-root:before": {
                                        content: '"•"',
                                        marginRight: "10px",
                                        marginLeft: "5px",
                                        fontSize: "1.5rem",
                                    },
                                }}
                            >
                                <ListItem sx={{ padding: "0.2rem 0" }}>
                                    Benefit from a cutting-edge web application
                                    deployed on the Microsoft Azure cloud
                                    service
                                </ListItem>
                                <ListItem sx={{ padding: "0.2rem 0" }}>
                                    Optimized Waitlist Management
                                </ListItem>
                                <ListItem sx={{ padding: "0.2rem 0" }}>
                                    Enhanced Guest Experience
                                </ListItem>
                                <ListItem sx={{ padding: "0.2rem 0" }}>
                                    Improved Operational Efficiency
                                </ListItem>
                                <ListItem sx={{ padding: "0.2rem 0" }}>
                                    Maximize Revenue
                                </ListItem>
                            </List>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "50px",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: "1.2rem",
                                        display: { xs: "none", md: "block" },
                                    }}
                                    onClick={() => handleButtonClick("Demo")}
                                >
                                    Request Free Demo&nbsp;
                                    <SendIcon />
                                </Button>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            justifyContent="center"
                            alignItems="center"
                            margin="auto"
                            sx={{ maxWidth: { xs: "250px", md: "100%" } }}
                        >
                            <img
                                src={`/images/mainpage/ai-woman-smile.png`}
                                style={{
                                    display: "block",
                                    width: "80%",
                                    maxWidth: "300px",
                                    margin: "auto",
                                    marginTop: "2rem",
                                    marginBottom: "2rem",
                                    borderRadius: "50%",
                                }}
                                alt="laugh woman"
                            />
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                sx={{
                                    fontWeight: 900,
                                    display: { xs: "block", md: "none" },
                                }}
                                onClick={() => handleButtonClick("Demo")}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    Request Free Demo&nbsp;
                                    <SendIcon />
                                </Box>
                            </Button>
                        </Grid>
                    </Grid>

                    <img
                        src={`/images/mainpage/peoplewaiting.png`}
                        style={{
                            display: "block",
                            width: "100%",
                            maxWidth: "800px",
                            margin: "auto",
                            marginTop: "3rem",
                        }}
                        alt="people in a row"
                    />
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            ml={{ xs: 0, lg: 10 }}
                            mr={{ xs: 0, lg: -10 }}
                            sx={{
                                backgroundColor: "WhiteSmoke",
                                padding: { xs: 5, md: 10 },
                            }}
                        >
                            <img
                                src={`/images/mainpage/Screenshot/GuestPage.png`}
                                style={{
                                    display: "block",
                                    minHeight: "300px",
                                    minWidth: "200px",
                                    maxHeight: "650px",
                                    maxWidth: "300px",
                                    margin: "auto",
                                }}
                                alt="queue details"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            ml={{ xs: 0, lg: -10 }}
                            mr={{ xs: 0, lg: 10 }}
                            sx={{
                                backgroundColor: "WhiteSmoke",
                                padding: { xs: 5, md: 10 },
                            }}
                        >
                            <img
                                src={`/images/mainpage/Screenshot/GuestDetailsPage.png`}
                                style={{
                                    display: "block",
                                    minHeight: "300px",
                                    minWidth: "200px",
                                    maxHeight: "650px",
                                    maxWidth: "300px",
                                    margin: "auto",
                                }}
                                alt="queue details"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            justifyContent="center"
                            alignItems="center"
                            display="block"
                            margin="auto"
                            sx={{ maxWidth: { xs: "60%", md: "100%" } }}
                        >
                            <img
                                src={`/images/mainpage/sampledish.png`}
                                style={{
                                    display: "block",
                                    width: "100%",
                                    maxWidth: "300px",
                                    margin: "auto",
                                    borderRadius: "50%",
                                }}
                                alt="sample dish"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h1"
                                gutterBottom
                                sx={{
                                    fontWeight: "700",
                                    fontSize: { xs: "1.3rem", md: "1.5rem" },
                                    textRendering: "optimizeLegibility",
                                    lineHeight: "2rem",
                                    marginTop: "1rem",
                                    paddingTop: 3,
                                }}
                            >
                                Enjoy a real-time web application deployed on
                                Microsoft Azure cloud service
                            </Typography>
                            <List
                                sx={{
                                    fontWeight: 500,
                                    marginTop: 0,
                                    marginBottom: { xs: 0, md: "3rem" },
                                    fontSize: {
                                        xs: "1rem",
                                        sm: "1.1rem",
                                        md: "1.2rem",
                                    },
                                    "& .MuiListItem-root:before": {
                                        content: '"•"',
                                        marginRight: "10px",
                                        marginLeft: "5px",
                                        fontSize: "1.5rem",
                                    },
                                }}
                            >
                                <ListItem sx={{ padding: "0.2rem 0" }}>
                                    Up to 1,000 bookings covered without any
                                    extra charges
                                </ListItem>
                                <ListItem sx={{ padding: "0.2rem 0" }}>
                                    Month-to-month contract without any hidden
                                    fees
                                </ListItem>
                                <ListItem sx={{ padding: "0.2rem 0" }}>
                                    24/7 support
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ backgroundColor: "WhiteSmoke" }}
                        >
                            <ListContext
                                mainTitle="Why Queuing Me?"
                                aboutArray={advantageData}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

interface CompanyPageProps {
    handleButtonClick: (page: string) => void;
}
