import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { User } from "../../app/models/user";
import { FieldValues } from "react-hook-form";
import agent from "../../app/api/agent";
import { router } from "../../app/layout/Routes";
import { toast } from "react-toastify";

interface AccountState {
    user: User | null;
}

const initialState: AccountState = {
    user: null,
};

export const signInUser = createAsyncThunk<User, FieldValues>(
    "account/signInUser",
    async (data, thunkAPI) => {
        try {
            const user = await agent.Account.login(data);
            localStorage.setItem("user", JSON.stringify(user));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
);

export const fetchCurrentUser = createAsyncThunk<User>(
    "account/fetchCurrentUser",
    async (_, thunkAPI) => {
        thunkAPI.dispatch(setUser(JSON.parse(localStorage.getItem("user")!)));
        try {
            const user = await agent.Account.currentUser();
            localStorage.setItem("user", JSON.stringify(user));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    },
    {
        condition: () => {
            if (!localStorage.getItem("user")) return false;
        },
    }
);

export const requestResetPasswordAdmin = createAsyncThunk(
    "account/requestResetPasswordAdmin",
    async (data: FieldValues, thunkAPI) => {
        try {
            await agent.PasswordReset.requestResetPasswordAdmin(data);
            toast.success("Please check your email for password reset instructions.");
        } catch (error: any) {
            console.error(error);
            toast.error("Failed to request password reset. Please double-check your current password.");
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const requestResetPasswordMember = createAsyncThunk(
    "account/requestResetPasswordMember",
    async (data: FieldValues, thunkAPI) => {
        try {
            await agent.PasswordReset.requestResetPasswordMember(data);
            toast.success("Please check your email for password reset instructions.");
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const resetPassword = createAsyncThunk(
    "account/resetPassword",
    async (data: FieldValues, thunkAPI) => {
        try {
            await agent.PasswordReset.resetPassword(data);
            toast.success("Your password has been reset successfully.");
            thunkAPI.dispatch(signOut());
        } catch (error: any) {
            console.error(error);
            toast.error(error.data);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        signOut: (state) => {
            state.user = null;
            localStorage.removeItem("user");
            router.navigate(`/login`);
        },
        setUser: (state, action) => {
            let claims = JSON.parse(atob(action.payload.token.split(".")[1]));
            let roles =
                claims[
                    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                ];
            state.user = {
                ...action.payload,
                roles: typeof roles === "string" ? [roles] : roles,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentUser.rejected, (state) => {
            state.user = null;
            localStorage.removeItem("user");
            toast.error("Session expired - Please login again");
            router.navigate(`/login`);
        });
        builder.addMatcher(
            isAnyOf(signInUser.fulfilled, fetchCurrentUser.fulfilled),
            (state, action) => {
                let claims = JSON.parse(
                    atob(action.payload.token.split(".")[1])
                );
                let roles =
                    claims[
                        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                    ];
                state.user = {
                    ...action.payload,
                    roles: typeof roles === "string" ? [roles] : roles,
                };
            }
        );
        builder.addMatcher(isAnyOf(signInUser.rejected), (state, action) => {
            // console.log(action.payload);
        });
    },
});

export const { signOut, setUser } = accountSlice.actions;
