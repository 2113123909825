import {
    Grid,
    Box,
    Typography,
    Link,
    Paper,
    Avatar,
    IconButton,
} from "@mui/material";
import RestaurantNameDisplay from "../../../app/configuration/restaurantNameDisplay";
import { Restaurant } from "../../../app/models/restaurant";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { orange } from "@mui/material/colors";

interface Props {
    restaurant: Restaurant;
    restaurantParam: string;
}

export default function GuestQueueDetailsHeader({
    restaurant,
    restaurantParam,
}: Props) {
    return (
        <Grid display="flex" height={100} p={1} mb={3}>
            <Grid
                display="flex"
                xs={3}
                container
                item
                alignItems="center"
                justifyContent="center"
            >
                <img
                    src={`/images/${restaurantParam}Icon.png`}
                    width="80%"
                    style={{ margin: "auto" }}
                    alt="logo"
                />
            </Grid>
            <Grid xs={7} item width="100%">
                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: "100%",
                        ml: 1,
                    }}
                >
                    <Typography variant="h6">
                        {RestaurantNameDisplay(restaurant.restaurantName)}
                    </Typography>
                    <Box display="flex" width="100%">
                        <Link
                            color="inherit"
                            href={"tel:" + restaurant.phoneNumber}
                            sx={{
                                p: "5px",
                            }}
                        >
                            <IconButton sx={{ padding: "1px" }}>
                                <PhoneIcon
                                    sx={{ color: "blue", fontSize: "20px" }}
                                />
                            </IconButton>
                        </Link>
                        <Link
                            color="inherit"
                            href={
                                "https://www.google.com/maps/search/?api=1&query=" +
                                restaurant.restaurantName
                            }
                            target="_blank"
                            sx={{
                                p: "5px",
                            }}
                        >
                            <IconButton sx={{ padding: "1px" }}>
                                <PlaceIcon sx={{ color: "#34A853" }} />
                            </IconButton>
                        </Link>

                        {/* 페이스북 링크 */}
                        {restaurant.restaurantOption.facebookUrl && (
                            <Link
                                color="inherit"
                                href={restaurant.restaurantOption.facebookUrl}
                                target="_blank"
                                sx={{
                                    p: "5px",
                                }}
                            >
                                <IconButton sx={{ padding: "1px" }}>
                                    <FacebookIcon sx={{ color: "#1877F2" }} />
                                </IconButton>
                            </Link>
                        )}

                        {/* 인스타그램 링크 */}
                        {restaurant.restaurantOption.instagramUrl && (
                            <Link
                                color="inherit"
                                href={restaurant.restaurantOption.instagramUrl}
                                target="_blank"
                                sx={{
                                    p: "5px",
                                }}
                            >
                                <IconButton sx={{ padding: "1px" }}>
                                    <InstagramIcon sx={{ color: "#E4405F" }} />
                                </IconButton>
                                {/* 인스타그램 고유 색상 */}
                            </Link>
                        )}

                        {/* 회사 홈페이지 링크 */}
                        {restaurant.restaurantOption.websiteUrl && (
                            <Link
                                color="inherit"
                                href={restaurant.restaurantOption.websiteUrl}
                                target="_blank"
                                sx={{
                                    p: "5px",
                                }}
                            >
                                <IconButton sx={{ padding: "1px" }}>
                                    <LanguageIcon />
                                </IconButton>
                            </Link>
                        )}
                    </Box>
                </Grid>
            </Grid>
            {restaurant.restaurantOption.menuImageUrl && (
                <Grid
                    xs={2}
                    item
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: 1,
                        }}
                    >
                        <Paper sx={{ padding: "10px" }}>
                            {restaurant.restaurantOption.menuImageUrl && (
                                <Link
                                    color="inherit"
                                    href={
                                        restaurant.restaurantOption.menuImageUrl
                                    }
                                    target="_blank"
                                    sx={{
                                        alignItems: "center",
                                        textDecoration: "none",
                                        gap: 0.5,
                                    }}
                                >
                                    <Typography textAlign={"center"} color={"grey"} fontWeight={500} fontSize={12}>
                                        Menu
                                    </Typography>
                                    <Avatar sx={{ bgcolor: orange[500] }}>
                                        <RestaurantMenuIcon />
                                    </Avatar>
                                </Link>
                            )}
                        </Paper>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}
