import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "../store/configureStore";
import { toast } from "react-toastify";
import CreateRestaurantDto from "../models/CreateRestaurantDto";
import { PaginatedResponse } from "../models/pagination";
import { RestaurantUrlDto } from "../models/restaurant";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
    const token = store.getState().account.user?.token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(
    (response) => {
        const pagination = response.headers["pagination"];
        if (pagination) {
            response.data = new PaginatedResponse(
                response.data,
                JSON.parse(pagination)
            );
            return response;
        }
        return response;
    },
    (error: AxiosError) => {
        const { data, status } = error.response as AxiosResponse;
        switch (status) {
            case 400:
                if (data.errors) {
                    const modelStateErrors: string[] = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modelStateErrors.push(data.errors[key]);
                        }
                    }
                    throw modelStateErrors.flat();
                }
                toast.error(data.title);
                console.log("400 Error");
                break;
            case 401:
                const message = data.detail ? `${data.title}: ${data.detail}` : data.title;
                toast.error(message);
                break;
            case 500:
                // router.navigate(`/server-error`, {
                //     state: { error: data },
                // });
                toast.error(data.title);
                break;
            default:
                break;
        }
        return Promise.reject(error.response);
    }
);

const requests = {
    // params for pagination
    get: (url: string, params?: URLSearchParams) =>
        axios.get(url, { params }).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
    // postForm: (url: string, data: FormData) => axios.post(url, data, {
    //     headers: {'Content-type': 'multipart/form-data'}
    // }).then(responseBody),
    putForm: (url: string, data: FormData) =>
        axios
            .put(url, data, {
                headers: { "Content-type": "multipart/form-data" },
            })
            .then(responseBody),
};

function createFormData(item: any) {
    let formData = new FormData();
    for (const key in item) {
        formData.append(key, item[key]);
    }
    return formData;
}

const Restaurant = {
    getRestaurants: () => requests.get(`/restaurant`),
    getRestaurantById: (id: number) => requests.get(`/restaurant/id/${id}`),
    getRestaurantByName: (restaurantName: string) =>
        requests.get(`/restaurant/name/${restaurantName}`),
    // Create a new restaurant (Only for SuperAdmin role)
    createRestaurant: (restaurantDto: CreateRestaurantDto) =>
        requests.post(`/restaurant/createRestaurant`, restaurantDto),
    updateMenuURL: (menuImageURL: string, file: File) => {
        const formData = new FormData();
        formData.append("file", file); // file을 formData에 직접 추가
        return requests.putForm(
            `/reservationOption/${menuImageURL}/upload-menu-image`,
            formData
        );
    },
    updateUrls: (restaurantName: string, urls: RestaurantUrlDto) =>
        requests.put(`/reservationOption/${restaurantName}/update-urls`, urls),
};

const Reservation = {
    list: (restaurantParam: string) =>
        requests.get(`queues/${restaurantParam}/getAllReservations`),
};

const Queue = {
    validateRoute: (restaurantParam: string, routeSuffix: string) =>
        requests.get(
            `queues/validateRoute?restaurantName=${restaurantParam}&routeSuffix=${routeSuffix}`
        ),
    list: (restaurantParam: string) =>
        requests.get(`queues/${restaurantParam}/getAllQueues`),
    listAllRestaurants: () => requests.get("queues/GetAllActiveQueues"),
    details: (id: string, restaurantParam: string) =>
        requests.get(`queues/${restaurantParam}/${id}`),
    // getMessage: (id: string) => requests.get(`queuess/getMessage/${id}`),
    create: (values: any) =>
        requests.post(`queues/${values.restaurantName}/createQueue`, values),
    updateStatusByAdmin: (values: any) =>
        requests.put(
            `queues/${values.restaurantName}/updateStatusByAdmin/${values.id}`,
            values
        ),
    updateStatusByGuest: (values: any) =>
        requests.put(
            `queues/${values.restaurantName}/updateStatusByGuest/${values.id}`,
            values
        ),
    updateQueue: (values: any) =>
        requests.put(`queues/${values.restaurantName}/${values.id}`, values),
    getArchived: (restaurantParam: string) =>
        requests.get(`queues/${restaurantParam}/getArchivedQueues`),
};

const Account = {
    login: (values: any) => requests.post("account/login", values),
    register: (values: any) => requests.post("account/register", values),
    currentUser: () => requests.get("account/currentUser"),
    membershipStatus: () => requests.get("account/membershipStatus"),
    unsubscribeAll: (values: any) =>
        requests.post("account/UnsubscribeAll", values),
    subscribe: (values: any) => requests.post("account/subscribe", values),
};

const Message = {
    sendInvitationMessage: (values: any) =>
        requests.post(`message/SendInvitationMessage`, values),
};

const Benefit = {
    getAllBenefitsFromAllRestaurants: () => requests.get("benefit"),
    getBenefitByRestaurantName: (
        restaurantName: string,
        membershipType: string
    ) =>
        requests.get(
            `benefit/${restaurantName}?membershipType=${membershipType}`
        ),
    createBenefit: (restaurantName: string, benefitDto: any) =>
        requests.post(`benefit/${restaurantName}/createBenefit`, benefitDto),
    updateBenefit: (id: string, updatedBenefit: any) =>
        requests.put(`benefit/${id}`, updatedBenefit),
    deactivateBenefit: (id: string) =>
        requests.put(`benefit/${id}/deactivate`, {}),
    reactivateBenefit: (id: string) =>
        requests.put(`benefit/${id}/reactivate`, {}),
    deleteBenefit: (id: string) => requests.delete(`benefit/${id}`),
};

const Promotion = {
    createPromotion: (values: any) =>
        requests.post(`promotionevent/${values.restaurantName}/create`, values),
    updatePromotion: (values: any) =>
        requests.put(`promotionevent/update/${values.id}`, values),
    deActivatePromotion: (values: any) =>
        requests.post(`promotionevent/deactivate/${values.id}`, values),
    joinPromotion: (values: any) =>
        requests.post(`promotionevent/join/${values.id}`, values),
    getAllPromotions: (restaurantParam: string) =>
        requests.get(
            `promotionevent/getAllActivePromotions?restaurantName=${restaurantParam}`
        ),
    getPromotionById: (promotionId: string) =>
        requests.get(`promotionevent/getPromotionById/${promotionId}`),
};

const Membership = {
    createMembership: (values: any) =>
        requests.post(`membership/createMembership`, values),
    deActivateMembership: (values: any) =>
        requests.post(`membership/deactivate/${values.id}`, values),
    reActivateMembership: (values: any) =>
        requests.post(`membership/reactivate/${values.id}`, values),
    unsubscribeMembership: (values: any) =>
        requests.post(`membership/unsubscribe/${values.id}`, values),
    subscribeMembership: (values: any) =>
        requests.post(`membership/subscribe/${values.id}`, values),
    updateMembershipType: (values: any) =>
        requests.put(`membership/updateMembershipType/${values.id}`, values),
    getBalance: (membershipId: string, restaurantName: string) =>
        requests.get(
            `/membership/getBalance?membershipId=${membershipId}&restaurantName=${restaurantName}`
        ),
    getAllMembership: (restaurantName: string, params: URLSearchParams) =>
        requests.get(
            `/membership/getAllMembership?restaurantName=${restaurantName}`,
            params
        ),
    usePoints: (values: any) =>
        requests.post(`membership/usePoints/${values.id}`, values),
    earnPoints: (values: any) =>
        requests.post(`membership/earnPoints/${values.id}`, values),
    getMembershipDetails: (restaurantName: string, membershipType: string) =>
        requests.get(
            `/membership/${restaurantName}?membershipType=${membershipType}`
        ),
    getMembershipById: (membershipId: string) =>
        requests.get(
            `/membership/getMembershipById?membershipId=${membershipId}`
        ),
};

const Statistics = {
    getWeeklyQueues: (values: any) => {
        return requests.post(
            `statistics/${values.restaurantName}/getWeeklyQueues`,
            values
        );
    },
    getQueueStatusCounts: (restaurantParam: string) =>
        requests.get(`statistics/${restaurantParam}/getQueueStatusCounts`),
    getWeeklyQueuesSize: (values: any) => {
        return requests.post(
            `statistics/${values.restaurantName}/getWeeklyQueuesSize`,
            values
        );
    },
    getWeeklyWaitingTime: (values: any) => {
        return requests.post(
            `statistics/${values.restaurantName}/getWeeklyWaitingTime`,
            values
        );
    },
};

const TestErrors = {
    get400Error: () => requests.get("error/bad-request"),
    get401Error: () => requests.get("error/unauthorized"),
    get404Error: () => requests.get("error/not-found"),
    get500Error: () => requests.get("error/server-error"),
    getValidationError: () => requests.get("error/validation-error"),
};

const PasswordReset = {
    requestResetPasswordAdmin: (values: any) =>
        requests.post("account/change-password", values),
    requestResetPasswordMember: (values: any) =>
        requests.post("account/member-change-password", values),
    resetPassword: (values: any) =>
        requests.post("account/reset-password", values),
};

const agent = {
    Restaurant,
    Queue,
    Account,
    TestErrors,
    Reservation,
    Message,
    Statistics,
    PasswordReset,
    Promotion,
    Membership,
    Benefit,
};

export default agent;
