import { Benefit } from "./benefit";
import { Restaurant } from "./restaurant";

export enum MembershipType {
    Basic = "Basic",
    Silver = "Silver",
    Gold = "Gold",
    Platinum = "Platinum",
    VIP = "VIP",
}

export interface MembershipResponse {
    membership: Membership;
    benefits: Benefit[];
}

export interface Membership {
    id: string;
    isActive: boolean;
    isSubscribed: boolean;
    membershipType: MembershipType;
    createdTime: string;
    userId: string;
    points: number;
    userName: string;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    membershipRestaurants: MembershipRestaurant[];
}

export interface MembershipRestaurant {
    id: string;
    restaurantId: number;
    membershipId: string;
    restaurant: Restaurant;
    subscriptionStatus: boolean;
}

export interface MembershipParams {
    orderBy?: string;
    searchTerm?: string;
    isSubscribed?: boolean;
    pageNumber: number;
    pageSize: number;
}