export const guestMsg = {
    GuestDetailMessage: {
        title: {
            waiting: "You are in the queue",
            notified: "You are notified",
            ready: "Your table is ready now",
            cancel: "You leave the queue",
            complete: "Your queue is ended",
            noshow: "Your queue is expired",
        },
        content: {
            footerEnglish:
                "If you make a mistake with your information details, please reach out to our server. Please Note that we can only hold the table for 5 minutes after it's ready. The seating orders are subject to change according to the restaurant's policy.",
        },
        menuPriceAlert: "Please note that menu prices are subject to change and may vary slightly from the listed price.",
        expectedWaitTime: "It may differ from the actual wait time.",
    },
};

export const membershipMsg = {
    GuestDetailMessage: {
        title: {
            basic: "basic",
            silver: "silver",
            gold: "gold",
            platinum: "platinum",
            vip: "VIP",
        },
    },
};
