import { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";
import { Box, Typography } from "@mui/material";
import { generateRouteSuffix, publicRoutes } from "../../configuration/globalConfig";

interface Props {
    restaurantName: string;
    bottom: number;
    right: number;
    size: number;
}

export default function QRcode({ restaurantName, bottom, right, size }: Props) {
    const qrRef = useRef<HTMLDivElement>(null);
    const qrCode = useRef<QRCodeStyling | null>(null);

    useEffect(() => {
        const updateQRCode = () => {
            const routeSuffix = generateRouteSuffix(restaurantName);
            console.log(routeSuffix);
            const dataUrl = `${process.env.REACT_APP_BASE_URL}/${restaurantName}/${publicRoutes}/guest?routeSuffix=${routeSuffix}`;

            if (qrCode.current) {
                qrCode.current.update({
                    data: dataUrl,
                });
            } else {
                qrCode.current = new QRCodeStyling({
                    width: size * 30,
                    height: size * 30,
                    data: dataUrl,
                    image: `/images/${restaurantName}Icon.png`,
                    imageOptions: {
                        crossOrigin: "anonymous",
                        margin: 5,
                    },
                    dotsOptions: {
                        color: "#000000",
                        type: "dots",
                    },
                    cornersSquareOptions: {
                        color: "#000000",
                        type: "classy",
                    },
                    cornersDotOptions: {
                        color: "#000000",
                        type: "extra-rounded",
                    },
                    backgroundOptions: {
                        color: "#ffffff",
                    },
                });

                if (qrRef.current) {
                    qrCode.current.append(qrRef.current);
                }
            }
        };

        const getNextUpdateTime = () => {
            const now = new Date();
            const nextUpdate = new Date(now);
            nextUpdate.setHours(2, 0, 0, 0); // 새벽 2시로 설정
            if (now >= nextUpdate) {
                // 이미 오늘 새벽 2시를 지났다면 다음 날로 설정
                nextUpdate.setDate(nextUpdate.getDate() + 1);
            }
            return nextUpdate.getTime() - now.getTime();
        };

        const scheduleUpdates = () => {
            // 처음 실행
            updateQRCode();

            // 다음 새벽 2시까지 남은 시간
            const initialDelay = getNextUpdateTime();
            const timeoutId = setTimeout(() => {
                updateQRCode();
                // 이후 매일 24시간마다 실행
                setInterval(updateQRCode, 24 * 60 * 60 * 1000);
            }, initialDelay);

            return () => clearTimeout(timeoutId); // Clean up timeout
        };

        const cancelUpdates = scheduleUpdates();

        return () => {
            cancelUpdates();
        };
    }, [restaurantName]);

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: `${bottom}%`,
                right: `${right}%`,
                zIndex: 999,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "white",
                padding: 1,
                borderRadius: "10px",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    marginBottom: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                }}
            >
                Scan to join waitlist
            </Typography>
            <div ref={qrRef} />
        </Box>
    );
}
