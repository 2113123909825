export const brandName = "QueuingMe";

/**
 * Restaurant URLs:
 * 
 * - Jejudo: https://www.queuingme.com/Jejudo/public-ip3b3t20/guest
 * - Toedam: https://www.queuingme.com/Toedam/public-ip3b3t20/guest
 * - Gooiga: https://www.queuingme.com/Gooiga/public-ip3b3t20/guest
 * - Yeunkyung: https://www.queuingme.com/Yeunkyung/public-ip3b3t20/guest
 * - MakchangDodook: https://www.queuingme.com/MakchangDodook/public-ip3b3t20/guest
 * - Otreat: https://www.queuingme.com/Otreat/public-ip3b3t20/guest
 * - QueuingMe: https://www.queuingme.com/QueuingMe/public-ip3b3t20/guest
 * - Midam: https://www.queuingme.com/Midam/public-ip3b3t20/guest
 * 
 * These are the base URLs for the restaurants' queue pages.
 */



/**
 * Restaurant URLs:
 * 
 * - Jejudo: https://www.queuingme.com/Jejudo/public-ip3b3t20/display
 * - Toedam: https://www.queuingme.com/Toedam/public-ip3b3t20/display
 * - Gooiga: https://www.queuingme.com/Gooiga/public-ip3b3t20/display
 * - Yeunkyung: https://www.queuingme.com/Yeunkyung/public-ip3b3t20/display
 * - MakchangDodook: https://www.queuingme.com/MakchangDodookAustin/public-ip3b3t20/display
 * - Otreat: https://www.queuingme.com/Otreat/public-ip3b3t20/display
 * - QueuingMe: https://www.queuingme.com/QueuingMe/public-ip3b3t20/display
 * - Midam: https://www.queuingme.com/Midam/public-ip3b3t20/display
 * 
 * These are the base URLs for the restaurants' queue pages.
 */
export const publicRoutes = "public-ip3b3t20";

export const generateRouteSuffix = (restaurantName: string) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const days = ["S", "M", "T", "W", "T", "F", "S"];
    const dayInitial = days[today.getDay()];
    const lastChar = restaurantName.slice(-1).toLowerCase();
    const baseValue = `${dayInitial}${month}${day}-${lastChar}${year}`;

    // Base64 encoding
    return btoa(baseValue);
};

export const getInitial = (name: string) => {
    return name.split(" ").map((n) => n.charAt(0)).join("").toUpperCase().slice(0, 3);
};

export const getCapitalizedName = (name: string) => {
    return name.split(" ").map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(" ");
};

export const getCapitalizedFirstName = (name: string) => 
    name.split(" ")[0].charAt(0).toUpperCase() + name.split(" ")[0].slice(1);

export function getPositionString(position: number): string {
    if (position <= 0) {
        return "Ready";
    }

    const lastDigit = position % 10;
    const lastTwoDigits = position % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return `${position}th in line`;
    }

    switch (lastDigit) {
        case 1:
            return `${position}st in line`;
        case 2:
            return `${position}nd in line`;
        case 3:
            return `${position}rd in line`;
        default:
            return `${position}th in line`;
    }
}