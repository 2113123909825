import {
    Container,
    Grid,
    Typography,
    Divider,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Box,
    CircularProgress,
    IconButton,
    Link,
} from "@mui/material";
import { primaryColor } from "../../../app/style/customColor";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../app/store/configureStore";
import { useEffect, useState } from "react";
import { Benefit, BenefitDto } from "../../../app/models/benefit";
import EditIcon from "@mui/icons-material/Edit";
import agent from "../../../app/api/agent";
import { MembershipType } from "../../../app/models/membership";
import { fetchCurrentUser } from "../../account/accountSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import MembershipRegisterQRScan from "../../guest/Membership/MembershipRegisterQRScan";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PlaceIcon from "@mui/icons-material/Place";
import { displayPhoneNumber } from "../../../app/configuration/phoneNumberConvertor";

export default function MembershipInfo() {
    const { user } = useAppSelector((state) => state.account);
    const dispatch = useAppDispatch();
    const [loadingBenefit, setLoadingBenefit] = useState<string | null>(null);
    const [openModal, setOpenModal] = useState<{ [key: string]: boolean }>({});

    const [newBenefit, setNewBenefit] = useState<BenefitDto>({
        id: "",
        membershipType: MembershipType.Basic,
        name: "",
        description: "",
        isActive: true,
        restaurantName: "",
    });

    const [currentBenefit, setCurrentBenefit] = useState<Benefit | null>(null);
    const [isUpdated, setIsUpdated] = useState(false);
    useEffect(() => {
        dispatch(fetchCurrentUser());
    }, [dispatch, isUpdated]);

    const [openQRDialog, setOpenQRDialog] = useState<number | null>(null);
    const handleOpenQRDialog = (restaurantId: number) =>
        setOpenQRDialog(restaurantId);
    const handleCloseQRDialog = () => setOpenQRDialog(null);

    if (!user) return null;

    const handleOpenModal = (restaurantName: string) => {
        setOpenModal((prevState) => ({
            ...prevState,
            [restaurantName]: true,
        }));
    };

    const handleCloseModal = (restaurantName: string) => {
        setCurrentBenefit(null);
        setOpenModal((prevState) => ({
            ...prevState,
            [restaurantName]: false,
        }));
    };

    const handleUpdatingOpenModal = (benefit: Benefit) => {
        setCurrentBenefit(benefit);
        setNewBenefit((prevState) => ({
            ...prevState,
            name: benefit.name,
            description: benefit.description,
        }));
        setOpenModal((prevState) => ({
            ...prevState,
            [benefit.id]: true,
        }));
    };

    const handleUpdatingCloseModal = (benefit: Benefit) => {
        setCurrentBenefit(null);
        setOpenModal((prevState) => ({
            ...prevState,
            [benefit.id]: false,
        }));
    };

    const handleBenefitSubmit = async (restaurantName: string) => {
        try {
            await agent.Benefit.createBenefit(
                restaurantName,
                newBenefit!
            );

            setIsUpdated(!isUpdated);
        } catch (error) {
            console.error("Error creating benefit:", error);
        } finally {
            handleCloseModal(restaurantName);
        }
    };

    const handleBenefitDeactivate = async (benefitId: string) => {
        setLoadingBenefit(benefitId);
        try {
            await agent.Benefit.deactivateBenefit(benefitId);
            setIsUpdated(!isUpdated);
        } catch (error) {
            console.error("Error deactivating benefit:", error);
        } finally {
            setLoadingBenefit(null);
        }
    };

    const handleBenefitActivate = async (benefitId: string) => {
        setLoadingBenefit(benefitId);
        try {
            await agent.Benefit.reactivateBenefit(benefitId);
            setIsUpdated(!isUpdated);
        } catch (error) {
            console.error("Error activating benefit:", error);
        } finally {
            setLoadingBenefit(null);
        }
    };

    const handleBenefitUpdate = async (benefitId: string, data: BenefitDto) => {
        setLoadingBenefit(benefitId);
        try {
            await agent.Benefit.updateBenefit(benefitId, data);
            setIsUpdated(!isUpdated);
        } catch (error) {
            console.error("Error updating benefit:", error);
        } finally {
            setLoadingBenefit(null);
            handleUpdatingCloseModal(currentBenefit!);
        }
    };

    const handleBenefitDelete = async (benefitId: string) => {
        setLoadingBenefit(benefitId);
        try {
            await agent.Benefit.deleteBenefit(benefitId);
            setIsUpdated(!isUpdated);
        } catch (error) {
            console.error("Error deleting benefit:", error);
        } finally {
            setLoadingBenefit(null);
        }
    };

    return (
        <Container>
            <Grid container marginTop={3}>
                <CardMembershipIcon fontSize="large" color="primary" />
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={primaryColor}
                    sx={{ marginLeft: 1 }}
                >
                    Membership
                </Typography>
            </Grid>
            <Divider />
            {user?.restaurants?.map((restaurant, idx) => {
                return (
                    <Box key={idx} mb={10}>
                        <Grid
                            container
                            spacing={2}
                            key={restaurant.id}
                            margin={2}
                        >
                            <Grid
                                item
                                xs={12}
                                md={2}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <img
                                    src={`/images/${restaurant.restaurantName}Icon.png`}
                                    style={{
                                        width: "100px",
                                    }}
                                    alt="logo"
                                />
                            </Grid>

                            <Grid item xs={12} md={10}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1.5,
                                            }}
                                        >
                                            {restaurant.restaurantName}{" "}
                                            <Link
                                                component="button"
                                                color="inherit"
                                                href={
                                                    "tel:" +
                                                    restaurant.phoneNumber
                                                }
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    gap: 0.5,
                                                }}
                                            >
                                                <PlaceIcon
                                                    sx={{ fontSize: "large" }}
                                                />
                                                <Typography variant="body2">
                                                    {displayPhoneNumber(
                                                        restaurant.phoneNumber
                                                    )}
                                                </Typography>
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Link
                                                color="inherit"
                                                href={
                                                    "https://www.google.com/maps/search/?api=1&query=" +
                                                    restaurant.restaurantName
                                                }
                                                target="blank"
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    gap: 0.5,
                                                }}
                                            >
                                                <PlaceIcon
                                                    sx={{
                                                        fontSize: "large",
                                                        verticalAlign: "middle",
                                                    }}
                                                />
                                                {`${restaurant.address?.street}, 
                                        ${restaurant.address?.city}, 
                                        ${restaurant.address?.province} 
                                        ${restaurant.address?.postalCode}`}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() =>
                                                handleOpenQRDialog(
                                                    restaurant.id
                                                )
                                            }
                                            startIcon={<QrCode2Icon />}
                                        >
                                            Membership Register QR code
                                        </Button>
                                        <Dialog
                                            open={
                                                openQRDialog === restaurant.id
                                            }
                                            onClose={handleCloseQRDialog}
                                        >
                                            <DialogTitle>
                                                Membership Register QR Code
                                            </DialogTitle>
                                            <DialogContent>
                                                <MembershipRegisterQRScan
                                                    restaurantName={
                                                        restaurant.restaurantName
                                                    }
                                                    membershipType={
                                                        MembershipType.Basic
                                                    } //TODO: if we add more membership type, we need to change this
                                                />
                                            </DialogContent>
                                            <IconButton
                                                onClick={handleCloseQRDialog}
                                                style={{
                                                    position: "absolute",
                                                    right: 10,
                                                    top: 10,
                                                }}
                                            >
                                                ✕
                                            </IconButton>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {restaurant.benefits!.length > 0 && (
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                variant="body2"
                                                fontWeight={700}
                                            >
                                                Membership Benefit
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ width: 80 }}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontWeight={700}
                                            >
                                                Status
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ width: 20 }}
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {restaurant.benefits?.map(
                                        (benefit: Benefit) => (
                                            <TableRow key={benefit.id}>
                                                <TableCell>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            fontWeight={500}
                                                            sx={{
                                                                color: benefit.isActive
                                                                    ? "initial"
                                                                    : "text.disabled", // Muted text for inactive
                                                            }}
                                                        >
                                                            {benefit.name}

                                                            <Button
                                                                size="small"
                                                                onClick={() =>
                                                                    handleUpdatingOpenModal(
                                                                        benefit
                                                                    )
                                                                }
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </Button>
                                                        </Typography>
                                                        {/* Modal for updating benefit */}
                                                        <Dialog
                                                            open={
                                                                openModal[
                                                                    benefit.id
                                                                ] || false
                                                            }
                                                            onClose={() =>
                                                                handleUpdatingCloseModal(
                                                                    benefit
                                                                )
                                                            }
                                                        >
                                                            <DialogTitle>
                                                                Edit Benefit
                                                                Details for{" "}
                                                                {benefit.name}
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <TextField
                                                                    label="Benefit Name"
                                                                    fullWidth
                                                                    value={
                                                                        newBenefit?.name ||
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setNewBenefit(
                                                                            (
                                                                                prevState
                                                                            ) => ({
                                                                                ...prevState,
                                                                                name: e
                                                                                    .target
                                                                                    .value,
                                                                            })
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        marginBottom: 2,
                                                                        marginTop: 2,
                                                                    }}
                                                                />
                                                                <TextField
                                                                    label="Description"
                                                                    multiline
                                                                    rows={6}
                                                                    fullWidth
                                                                    value={
                                                                        newBenefit?.description ||
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setNewBenefit(
                                                                            (
                                                                                prevState
                                                                            ) => ({
                                                                                ...prevState,
                                                                                description:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            })
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        marginBottom: 2,
                                                                    }}
                                                                />
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() =>
                                                                        handleBenefitUpdate(
                                                                            benefit.id,
                                                                            newBenefit
                                                                        )
                                                                    }
                                                                >
                                                                    Submit
                                                                </Button>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleUpdatingCloseModal(
                                                                            benefit
                                                                        )
                                                                    }
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </Box>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: benefit.isActive
                                                                ? "initial"
                                                                : "text.disabled", // Muted text for inactive
                                                            whiteSpace:
                                                                "pre-wrap",
                                                        }}
                                                    >
                                                        {benefit.description}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        color={
                                                            benefit.isActive
                                                                ? "success"
                                                                : "warning"
                                                        }
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() =>
                                                            benefit.isActive
                                                                ? handleBenefitDeactivate(
                                                                      benefit.id
                                                                  )
                                                                : handleBenefitActivate(
                                                                      benefit.id
                                                                  )
                                                        }
                                                        disabled={
                                                            loadingBenefit ===
                                                            benefit.id
                                                        } // Disable if loading
                                                        startIcon={
                                                            loadingBenefit ===
                                                            benefit.id ? (
                                                                <CircularProgress
                                                                    size={20}
                                                                    color="inherit"
                                                                />
                                                            ) : null
                                                        }
                                                    >
                                                        {benefit.isActive
                                                            ? loadingBenefit ===
                                                              benefit.id
                                                                ? ""
                                                                : "Active"
                                                            : loadingBenefit ===
                                                              benefit.id
                                                            ? ""
                                                            : "Inactive"}
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <DeleteIcon
                                                        color="error"
                                                        onClick={() => {
                                                            handleBenefitDelete(
                                                                benefit.id
                                                            );
                                                        }}
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        )}

                        <Button
                            onClick={() => {
                                setNewBenefit((prevState) => ({
                                    ...prevState,
                                    name: "",
                                    description: "",
                                }));
                                handleOpenModal(restaurant.restaurantName);
                            }}
                        >
                            Add more benefits
                        </Button>
                        <Divider />

                        {/* Modal for adding benefits */}
                        <Dialog
                            open={openModal[restaurant.restaurantName] || false}
                            onClose={() =>
                                handleCloseModal(restaurant.restaurantName)
                            }
                        >
                            <DialogTitle>Add New Benefit</DialogTitle>
                            <DialogContent>
                                <TextField
                                    label="Benefit Name"
                                    fullWidth
                                    value={newBenefit.name}
                                    onChange={(e) =>
                                        setNewBenefit((prevState) => ({
                                            ...prevState,
                                            name: e.target.value,
                                        }))
                                    }
                                    sx={{ marginBottom: 2, marginTop: 2 }}
                                />
                                <TextField
                                    label="Description"
                                    fullWidth
                                    value={newBenefit.description || ""}
                                    onChange={(e) =>
                                        setNewBenefit((prevState) => ({
                                            ...prevState,
                                            description: e.target.value,
                                        }))
                                    }
                                    sx={{ marginBottom: 2 }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        handleBenefitSubmit(
                                            restaurant.restaurantName
                                        )
                                    }
                                >
                                    Submit
                                </Button>
                                <Button
                                    onClick={() =>
                                        handleCloseModal(
                                            restaurant.restaurantName
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Divider />
                    </Box>
                );
            })}
        </Container>
    );
}
