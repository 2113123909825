import { MembershipType } from "../../../app/models/membership";
import QRCode from "react-qr-code";
import { Button } from "@mui/material";
import html2canvas from "html2canvas"; // Import html2canvas

interface Props {
    restaurantName: string;
    membershipType: MembershipType;
}

export default function MembershipRegisterQRScan({
    restaurantName,
    membershipType,
}: Props) {
    if (!restaurantName || !membershipType) return null;

    const membershipUrl = `${process.env.REACT_APP_BASE_URL}/membership?restaurantName=${restaurantName}&membershipType=${membershipType}`;

    const handleDownload = (format: "png" | "jpg") => {
        const qrContainer = document.getElementById("qr-container");
        if (!qrContainer) return;

        // Capture the container (QR code + centered image) as a canvas
        html2canvas(qrContainer).then((canvas) => {
            // Convert canvas to data URL
            const imageUrl = canvas.toDataURL(`image/${format}`);

            // Create a download link and trigger it
            const link = document.createElement("a");
            link.href = imageUrl;
            link.download = `membership-qr-code.${format}`;
            link.click();
        });
    };

    return (
        <div>
            <div
                id="qr-container"
                style={{ position: "relative", display: "inline-block" }}
            >
                <QRCode
                    value={membershipUrl}
                    size={500}
                    id="qr-svg" // Use an ID to target the SVG element
                />
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)", // Centers the image
                        width: "111px", // Dynamically set the width based on the QR code size
                        height: "111px", // Dynamically set the height based on the QR code size
                        backgroundColor: "white", // White background for better visibility
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10, // Ensure the image is on top of the QR code
                        flexDirection: "column", // Stack the text and logo vertically
                    }}
                >
                    <div
                        style={{
                            marginTop: "5px",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        Membership
                    </div>
                    <img
                        src={`/images/${restaurantName}Icon.png`}
                        alt="restaurant name"
                        style={{
                            width: "100%", // Use 100% width/height to fit inside the container
                            objectFit: "contain", // Ensure the logo fits within the container without distortion
                        }}
                    />
                </div>
            </div>
            <div>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleDownload("png")}
                    style={{ marginTop: "10px" }}
                >
                    Download as PNG
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleDownload("jpg")}
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                >
                    Download as JPG
                </Button>
            </div>
        </div>
    );
}
