import { LoadingButton } from "@mui/lab";
import { Box, Container, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { useLocation } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { resetPassword } from "../../account/accountSlice";
import { useAppDispatch } from "../../../app/store/configureStore";

export default function PasswordResetPage() {
    const location = useLocation();
    const [userId, setUserId] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const userIdParam = searchParams.get("userId");
        const tokenParam = searchParams.get("token");
        if (userIdParam) {
            setUserId(userIdParam);
            console.log(userId);
        }
        if (tokenParam) {
            setToken(tokenParam);
            console.log(token);
        }
    }, [location, token, userId]);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        mode: "onTouched",
    });
    async function submitForm(data: FieldValues) {
        try {
            await dispatch(resetPassword(data));
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    if (!userId && !token) return <LoadingComponent message="Loading..." />;

    return (
        <Container component={Paper} maxWidth="xs">
            <Box
                component="form"
                onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{ mt: 1 }}
            >
                <div style={{ height: "0px", overflow: "hidden" }}>
                    <TextField value={userId} {...register("userId")} />
                    <TextField value={token} {...register("token")} />
                </div>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="New Password"
                    type="password"
                    {...register("newPassword", {
                        required: "Password is required",
                        pattern: {
                            value: /(?=^.{6,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message:
                                "Password expects atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 6-16 characters.",
                        },
                    })}
                    error={!!errors.newPassword}
                    helperText={errors?.newPassword?.message as string}
                />
                <TextField
                margin="normal"
                required
                fullWidth
                label="Confirm Password"
                type="password"
                {...register("confirmPassword", {
                    required: "Please confirm your password",
                    validate: (value) => {
                        const newPassword = getValues("newPassword");
                        return value === newPassword || "Passwords do not match";
                    },
                })}
                error={!!errors.confirmPassword}
                helperText={errors?.confirmPassword?.message as string}
            />
                <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Update Password
                </LoadingButton>
            </Box>
        </Container>
    );
}
