import { useEffect, useState } from "react";
import {
    Typography,
    Grid,
    Box,
} from "@mui/material";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import agent from "../../../app/api/agent";
import { Restaurant } from "../../../app/models/restaurant";
import MembershipRestaurantList from "../../membership/MembershipRestaurantList";

const NoMembershipMessage = () => {
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const response = await agent.Restaurant.getRestaurants();
                setRestaurants(response);
            } catch (error) {
                console.error("Error fetching restaurants:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRestaurants();
    }, []);

    if (loading) return <LoadingComponent message="Fetching restaurants..." />;

    return (
        <Box
            sx={{
                maxWidth: 450,
                margin: "auto",
                padding: "20px",
                backgroundColor: "white",
            }}
        >
            <Box
                sx={{
                    backgroundColor: "#fff",
                    padding: 2,
                    marginTop: 2,
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>
                    No Membership Available
                </Typography>
                <Typography variant="body1" align="center" gutterBottom>
                    Please visit the following restaurants to join memberships
                </Typography>
            </Box>
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
                {restaurants.map((restaurant) => (
                    <Grid
                        item
                        xs={12}
                        key={restaurant.id}
                        sx={{ maxWidth: "450px" }}
                    >
                        <MembershipRestaurantList restaurant={restaurant} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default NoMembershipMessage;
