import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityState,
} from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { toast } from "react-toastify";
import { Membership, MembershipParams } from "../../app/models/membership";
import { RootState } from "../../app/store/configureStore";
import { MetaData } from "../../app/models/pagination";

interface membershipState {
    membershipLoaded: boolean;
    status: string;
    membershipParams: MembershipParams;
    metaData: MetaData | null;
}

const membershipAdapter = createEntityAdapter<Membership>();

const getAxiosParams = (membershipParams: MembershipParams) => {
    const params = new URLSearchParams();
    params.append("pageNumber", membershipParams.pageNumber.toString());
    params.append("pageSize", membershipParams.pageSize.toString());
    if (membershipParams.orderBy)
        params.append("orderby", membershipParams.orderBy);
    if (membershipParams.searchTerm)
        params.append("searchTerm", membershipParams.searchTerm);
    if (membershipParams.isSubscribed)
        params.append("isSubscribed", membershipParams.isSubscribed.toString());
    return params;
};

export const getAllMembershipAsync = createAsyncThunk<
    Membership[],
    string,
    { state: RootState }
>("membership/getAllMembershipAsync", async (restaurantName, thunkAPI) => {
    const params = getAxiosParams(
        thunkAPI.getState().membership.membershipParams
    );
    try {
        const response = await agent.Membership.getAllMembership(
            restaurantName,
            params
        );
        thunkAPI.dispatch(setMetaData(response.metaData));
        return response.items;
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.data });
    }
});

// Make it easy to reset the params
const initParams = () => {
    return {
        pageNumber: 1,
        pageSize: 10,
    };
};

export const membershipSlice = createSlice({
    name: "membership",
    initialState: membershipAdapter.getInitialState<membershipState>({
        membershipParams: initParams(),
        membershipLoaded: false,
        status: "idle",
        metaData: null,
    }),
    reducers: {
        setMembershipParams: (state, action) => {
            state.membershipLoaded = false;
            state.membershipParams = {
                ...state.membershipParams,
                ...action.payload,
                pageNumber: 1,
            };
        },
        setPageNumber: (state, action) => {
            state.membershipLoaded = false;
            state.membershipParams = {
                ...state.membershipParams,
                ...action.payload,
            };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetMembershipParams: (state) => {
            state.membershipParams = initParams();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllMembershipAsync.pending, (state) => {
            state.status = "loading";
        });
        builder.addCase(getAllMembershipAsync.fulfilled, (state, action) => {
            membershipAdapter.setAll(
                state as EntityState<Membership>,
                action.payload
            );
            state.membershipLoaded = true;
            state.status = "idle";
        });
        builder.addCase(getAllMembershipAsync.rejected, (state) => {
            state.membershipLoaded = false;
            state.status = "idle";

            // Check if no toast is currently active
            if (!toast.isActive("membershipError")) {
                toast.error("Can't load membership data", {
                    toastId: "membershipError", // Assign a unique ID to the toast
                });
            }
        });
    },
});

export const membershipSelectors = membershipAdapter.getSelectors(
    (state: RootState) => state.membership
);

export const {
    setMembershipParams,
    setMetaData,
    setPageNumber,
    resetMembershipParams,
} = membershipSlice.actions;
