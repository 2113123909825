import { LoadingButton } from "@mui/lab";
import {
    Card,
    CardContent,
    Checkbox,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import agent from "../../../app/api/agent";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import { Queue } from "../../../app/models/queue";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { MuiTelInput } from "mui-tel-input";
import {
    phoneNumberConvertor,
    phoneNumberConvertorReverse,
} from "../../../app/configuration/phoneNumberConvertor";
import { BBQrestaurants, restaurant } from "../../../app/configuration/restaurant";
import NotFound from "../../errors/NotFound";

export default function UpdateQueuePage() {
    const { id, restaurantParam } = useParams<{
        id: string;
        restaurantParam: string;
    }>();
    const [queue, setQueue] = useState<Queue>();
    const [loading, setLoading] = useState(true);
    const [phone, setPhone] = useState("+1");
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors, isValid },
    } = useForm({
        mode: "all",
    });
    register("id", { value: id });

    const handleChange = (newPhone: string) => {
        setPhone(() => {
            if (newPhone.length <= 2) return "+1";
            return newPhone;
        });
    };

    useEffect(() => {
        agent.Queue.details(id!, restaurantParam!).then((res) => {
            res.id = id;
            setQueue(res);
            register("status", { value: res.status });
            setLoading(false);
            setPhone(phoneNumberConvertorReverse(res.phoneNumber));
        });
    }, []); //eslint-disable-line

    if (loading) return <LoadingComponent message="Loading..." />;

    if (!queue) return <NotFound />;

    if (!restaurant.includes(restaurantParam!.toLowerCase()))
        return <NotFound />;

    return (
        <>
            <Card
                style={{ maxWidth: 450, margin: "0 auto", padding: "20px 5px" }}
            >
                <Typography variant="h6" component="h6" align="center">
                    Update User Information
                </Typography>
                <CardContent>
                    <Box
                        component="form"
                        onSubmit={handleSubmit((data) => {
                            if (data.size >= 15) {
                                alert("We don't accept size of 15 or more.");
                                return;
                            }
                            data.phoneNumber = phoneNumberConvertor(
                                data.phoneNumber
                            );
                            data.restaurantName = restaurantParam;
                            agent.Queue.updateQueue(data)
                                .then((response) => {
                                    alert("Successfully updated!");
                                })
                                .catch((error) => console.error(error.response))
                                .finally(() => {
                                    navigate(`/${restaurantParam}/admin`);
                                });
                        })}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <Grid container spacing={1}>
                            <Grid xs={12} item>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Name"
                                    defaultValue={queue!.userName}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("userName", {
                                        required: "userName is required",
                                    })}
                                    error={!!errors.userName}
                                    helperText={errors?.userName?.message?.toString()}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <MuiTelInput
                                    required
                                    fullWidth
                                    id="outlined-number"
                                    label="Phone number"
                                    defaultCountry="CA"
                                    autoComplete="do-not-autofill"
                                    margin="normal"
                                    {...register("phoneNumber", {
                                        required: "Phone is required",
                                        pattern: {
                                            value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, //eslint-disable-line
                                            message:
                                                "Valid format: +1 604 774 7985",
                                        },
                                    })}
                                    onChange={handleChange}
                                    value={phone}
                                    onlyCountries={["CA", "US"]}
                                    error={!!errors.phoneNumber}
                                    helperText={errors?.phoneNumber?.message?.toString()}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-number"
                                    label="Party Size(Age 10+)"
                                    type="number"
                                    margin="normal"
                                    defaultValue={queue!.size.toString()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("size", {
                                        required: "Size is required",
                                        pattern: {
                                            value: /^([1-9]|1[0-4])$/,
                                            message:
                                                "Self check-in is available for up to a group of 14. If 15 or more, please reach out to a server.",
                                        },
                                    })}
                                    inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                    }}
                                    error={!!errors.size}
                                    helperText={errors?.size?.message?.toString()}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField
                                    fullWidth
                                    id="outlined-number"
                                    label="Kids(Age 10-)"
                                    type="number"
                                    placeholder="0"
                                    margin="normal"
                                    defaultValue={queue!.sizeKids.toString()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("sizeKids", {
                                        pattern: {
                                            value: /^([0-9])$/,
                                            message:
                                                "We accept 1-9 Kids at a time.",
                                        },
                                    })}
                                    inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                    }}
                                    error={!!errors.sizeKids}
                                    helperText={errors?.sizeKids?.message?.toString()}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    fullWidth
                                    id="outlined-required"
                                    label="Note"
                                    placeholder="Update Message"
                                    margin="normal"
                                    defaultValue={queue!.note.toString()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("note")}
                                    error={!!errors.note}
                                    helperText={errors?.note?.message?.toString()}
                                />
                            </Grid>

                            {BBQrestaurants.includes(
                                restaurantParam!.toLowerCase()
                            ) && (
                                <>
                                    <Grid xs={12} item>
                                        <Box
                                            sx={{
                                                display: "block",
                                                margin: "auto",
                                                width: "25%",
                                            }}
                                        >
                                            <Checkbox
                                                defaultChecked={queue?.isBbq!}
                                                {...register("isBbq")}
                                            />
                                            <OutdoorGrillIcon
                                                sx={{ verticalAlign: "middle" }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Typography
                                            color={"red"}
                                            variant="body1"
                                            align="center"
                                        >
                                            Make sure to update the BBQ box if changed
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            <Grid xs={12} sm={6} item>
                                <LoadingButton
                                    fullWidth
                                    loading={isSubmitting}
                                    disabled={!isValid}
                                    variant="contained"
                                    type="submit"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    SUBMIT
                                </LoadingButton>
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <LoadingButton
                                    fullWidth
                                    variant="outlined"
                                    onClick={() =>
                                        navigate(`/${restaurantParam}/admin`)
                                    }
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    CANCEL
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}
