import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Queue, Status } from "../../../app/models/queue";
import agent from "../../../app/api/agent";
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Link,
    List,
    ListItem,
    Typography,
} from "@mui/material";
import {
    StyledDashBoardTableCell,
    StyledDashBoardTableRow,
    dashBoardFontSize,
    dashBoardStatusFontSize,
} from "../../../app/style/customStyle";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { displayPhoneNumber } from "../../../app/configuration/phoneNumberConvertor";
import { useLocation, useParams } from "react-router-dom";
import { restaurant } from "../../../app/configuration/restaurant";
import NotFound from "../../errors/NotFound";
import * as signalR from "@microsoft/signalr";
import queryString from "query-string";
import QRcode from "../../../app/layout/partComponents/QRcode";
import withRotation from "../../../app/layout/withRotation";
import { getCapitalizedFirstName, publicRoutes } from "../../../app/configuration/globalConfig";

function DisplayPage() {
    const [queues, setQueues] = useState<Queue[]>([]);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const [notification, setNotification] = useState("");
    const [loading, setLoading] = useState(true);
    const { pathname, search } = useLocation();
    const queryParams = queryString.parse(search);
    const bottomOffset = queryParams.bottom
        ? parseInt(queryParams.bottom as string)
        : 2;
    const rightOffset = queryParams.right
        ? parseInt(queryParams.right as string)
        : 2;
    const qrCodeSize = queryParams.size
        ? parseInt(queryParams.size as string)
        : 10;
    const isRotated = queryParams.rotate !== undefined;
    const isPublicPath = pathname.includes(`/${publicRoutes}/`);
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/${restaurantParam}/${publicRoutes}/display`;

    useEffect(() => {
        // signalR connection
        const connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.None)
            .withUrl(`/DbUpdateHub?restaurantName=${restaurantParam}`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .build();

        connection.start();

        connection.on("ReceiveNotification", (rest, msg) => {
            if (rest.toLowerCase() === restaurantParam?.toLowerCase())
                setNotification(msg);
        });
        return () => {
            connection.stop();
        };
    }, []); //eslint-disable-line

    useEffect(() => {
        agent.Queue.list(restaurantParam!).then((queues) => {
            setQueues(queues);
            setLoading(false);
        });
    }, [notification]); //eslint-disable-line

    useEffect(() => {
        const interval = setInterval(() => {
            agent.Queue.list(restaurantParam!).then((queues) => {
                setQueues(queues);
            });
        }, 1800000); // 30 minutes

        return () => clearInterval(interval);
    }, [restaurantParam]);

    if (loading) return <LoadingComponent message="Loading..." />;

    if (!restaurant.includes(restaurantParam!.toLowerCase())) {
        return <NotFound />;
    }

    return (
        <div style={{ display: "block", margin: "40px" }}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    backgroundColor: "white",
                    borderRadius: "10px 10px 0 0",
                    minHeight: "80px",
                }}
            >
                <img
                    src={`/images/${restaurantParam}Icon.png`}
                    style={{
                        maxHeight: "90px",
                        padding: "5px",
                        maxWidth: "15%",
                        margin: "5px",
                    }}
                    alt="logo"
                />
                <Typography
                    sx={{ fontSize: dashBoardFontSize }}
                    fontWeight="900"
                >
                    Waiting List
                </Typography>
            </Box>

            <TableContainer
                component={Paper}
                sx={{ borderRadius: "0 0 10px 10px" }}
            >
                <Table sx={{ width: "100%" }} aria-label="a simple table">
                    <TableHead sx={{ height: "80px" }}>
                        <TableRow>
                            <StyledDashBoardTableCell>
                                Queue
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Name
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell
                                sx={{
                                    display: {
                                        "@media (max-height: 1000px)": {
                                            display: isRotated
                                                ? "none"
                                                : "table-cell",
                                        },
                                    },
                                }}
                            >
                                Phone Number
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Party Size
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Status
                            </StyledDashBoardTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {queues.map((row, idx) => (
                            <StyledDashBoardTableRow
                                key={row.id.toString()}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <StyledDashBoardTableCell
                                    component="th"
                                    scope="row"
                                >
                                    {idx + 1}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    {getCapitalizedFirstName(row.userName)}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell
                                    sx={{
                                        display: {
                                            "@media (max-height: 1000px)": {
                                                display: isRotated
                                                    ? "none"
                                                    : "table-cell",
                                            },
                                        },
                                    }}
                                >
                                    {displayPhoneNumber(row.phoneNumber)}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    {row.size}{" "}
                                    {(row.sizeKids !== 0 ||
                                        row.sizeKids == null) &&
                                        " + " + row.sizeKids}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    <Button
                                        variant={
                                            row.status === Status.Waiting
                                                ? "outlined"
                                                : "contained"
                                        }
                                        color={
                                            row.status === Status.Ready
                                                ? "success"
                                                : "primary"
                                        }
                                        sx={{
                                            minWidth: "200px",
                                            width: "250px",
                                            fontSize: dashBoardStatusFontSize,
                                        }}
                                    >
                                        {row.status}
                                    </Button>
                                </StyledDashBoardTableCell>
                            </StyledDashBoardTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {isPublicPath && (
                <QRcode
                    restaurantName={restaurantParam!}
                    bottom={bottomOffset}
                    right={rightOffset}
                    size={qrCodeSize}
                />
            )}

            {!isPublicPath && (
                <Container sx={{ mt: 2 }}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                QR Code Display Settings Guide
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Please refresh the display page at the entrance
                                if the system doesn't work.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                You can adjust the QR code display on the screen
                                by adding the following parameters to the URL:
                            </Typography>
                            <List sx={{ pl: 2 }}>
                                <ListItem>
                                    <Typography variant="body2">
                                        <strong>size:</strong> The size of the
                                        QR code (default: 10).
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body2">
                                        <strong>bottom:</strong> Vertical offset
                                        of the QR code from the bottom of the
                                        screen (default: 2).
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body2">
                                        <strong>right:</strong> Horizontal
                                        offset of the QR code from the right of
                                        the screen (default: 2).
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body2">
                                        <strong>rotate:</strong> Rotates the
                                        display area counterclockwise by 90°
                                        (default: 0°, specified in degrees).
                                    </Typography>
                                </ListItem>
                            </List>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                <strong>Example:</strong>
                                <br />
                                <Link
                                    href={`${baseUrl}?size=15&bottom=4&right=20`}
                                    underline="hover"
                                    component="a"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <code>{`${baseUrl}?size=15&bottom=4&right=20`}</code>
                                </Link>
                                <br />
                                This URL adjusts the QR code size to 1.5 times
                                bigger and moves it up-left.
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                <strong>Example:</strong>
                                <br />
                                <Link
                                    href={`${baseUrl}?size=20&bottom=-90&right=2&rotate`}
                                    underline="hover"
                                    component="a"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <code>{`${baseUrl}?size=20&bottom=-90&right=2&rotate`}</code>
                                </Link>
                                <br />
                                This URL adjusts the QR code size to 2 times
                                bigger, moves it down, and rotates it 90°
                                counterclockwise.
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
            )}
        </div>
    );
}

export default withRotation(DisplayPage);
