import { Paper, Typography, Box } from "@mui/material";


export default function InvalidQRCode() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Paper
                elevation={3}
                sx={{
                    padding: 3,
                    maxWidth: 400,
                    width: "100%",
                    textAlign: "center",
                }}
            >
                <Typography variant="h6" color="error" gutterBottom>
                    Invalid QR Code
                </Typography>
                <Typography variant="body1" color="textSecondary">
                        The QR code is not valid. Please scan at the restaurant.
                </Typography>
            </Paper>
        </Box>
    );
}
