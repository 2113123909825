import { Paper, debounce, TextField, IconButton, Divider } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/store/configureStore";
import { setMembershipParams } from "../membership/membershipSlice";
import { useCallback, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const MembershipSearch = () => {
    const { membershipParams } = useAppSelector((state) => state.membership);
    const [searchTerm, setSearchTerm] = useState(membershipParams.searchTerm);
    const dispatch = useAppDispatch();

    // debouncedSearch를 useCallback으로 정의하여 메모리 누수를 방지하고, 최신 값만 처리
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((value: string) => {
            dispatch(setMembershipParams({ searchTerm: value }));
        }, 1000),
        [] // 의존성 없음 (초기 1회만 생성)
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value); // 즉시 로컬 상태 업데이트
        debouncedSearch(value); // debounce 처리된 검색 호출
    };

    return (
        <Paper
            component="form"
            sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                borderRadius: { xs: "4px 4px 0 0", sm: "4px 0 0 0" },
            }}
        >
            <IconButton type="button" aria-label="search" sx={{ p: "4px" }}>
                <SearchIcon fontSize="small" />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <TextField
                sx={{
                    ml: 1,
                    flex: 1,
                    fontSize: "0.5rem",
                    "& .MuiInputBase-input::placeholder": {
                        fontSize: "0.8rem",
                    },
                }}
                placeholder="Search Membership"
                variant="standard"
                value={searchTerm || ""}
                onChange={handleSearchChange}
                inputProps={{ "aria-label": "search membership" }}
                InputProps={{
                    disableUnderline: true, // 밑줄 제거
                }}
            />
        </Paper>
    );
};

export default MembershipSearch;