import { Guid } from "guid-typescript";
import { Restaurant } from "./restaurant";

export interface Queue {
    id: Guid;
    size: number;
    sizeKids: number;
    userName: string;
    phoneNumber: string;
    isBbq: boolean;
    note: string;
    status: Status;
    createdTime: string;
    notifiedTime: string;
    readyTime: string;
    archivedTime: string;
    position?: number;
    restaurant: Restaurant;
}

export enum Status {
    Waiting = "Waiting",
    Notified = "Notified",
    Ready = "Ready",
    Complete = "Complete",
    Cancel = "Cancel",
    NoShow = "NoShow",
}