import { Card, CardHeader, Avatar, IconButton, Link } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { displayPhoneNumber } from "../../app/configuration/phoneNumberConvertor";
import { Restaurant } from "../../app/models/restaurant";

interface MembershipRestaurantListProps {
    restaurant: Restaurant;
}

const MembershipRestaurantList = ({ restaurant }: MembershipRestaurantListProps) => {
    return (
        <Card elevation={3} sx={{ width: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: "#eaeaea" }}>
                        <img
                            alt={restaurant.restaurantName}
                            src={`/images/${restaurant.restaurantName}Icon.png`}
                            width="100%"
                        />
                    </Avatar>
                }
                action={
                    <IconButton
                        aria-label="location"
                        onClick={() =>
                            window.open(
                                `https://maps.google.com/?q=${restaurant.restaurantName}`,
                                "_blank"
                            )
                        }
                    >
                        <LocationOnIcon />
                    </IconButton>
                }
                title={restaurant.restaurantName}
                subheader={
                    <Link
                        color="inherit"
                        href={"tel:" + restaurant.phoneNumber}
                        underline="hover"
                    >
                        {displayPhoneNumber(restaurant.phoneNumber)}
                    </Link>
                }
            />
        </Card>
    );
};

export default MembershipRestaurantList;
