import { Box, Typography, Pagination } from "@mui/material";
import { MetaData } from "../models/pagination";

interface Props {
    metaData: MetaData;
    onPageChange: (page: number) => void;
}

export default function AppPagination({ metaData, onPageChange }: Props) {
    const { currentPage, totalCount, totalPages, pageSize } = metaData;
    return (
        <Box display="flex" justifyContent="right" alignItems="center">
            <Box textAlign="right" mr={2}>
                <Typography variant="caption">
                    Displaying {(currentPage - 1) * pageSize + 1} -{" "}
                    {currentPage * pageSize > totalCount
                        ? totalCount
                        : currentPage * pageSize}{" "}
                    of {totalCount} items
                </Typography>
            </Box>
            {totalPages > 1 && (
                <Pagination
                    color="primary"
                    size="medium"
                    count={totalPages}
                    page={currentPage}
                    onChange={(e, page) => onPageChange(page)}
                />
            )}
        </Box>
    );
}
