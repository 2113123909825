import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import agent from "../../../app/api/agent";
import { MembershipResponse } from "../../../app/models/membership";
import { useState, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    Link,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { Restaurant } from "../../../app/models/restaurant";
import RestaurantNameDisplay from "../../../app/configuration/restaurantNameDisplay";
import { displayPhoneNumber } from "../../../app/configuration/phoneNumberConvertor";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../app/store/configureStore";
import { fetchCurrentUser } from "../../account/accountSlice";
import VirtualMembershipCard from "./VirtualMembershipCard";

export default function MembershipDetailsPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [membership, setMembership] = useState<MembershipResponse>();
    const [restaurant, setRestaurant] = useState<Restaurant>();
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        dispatch(fetchCurrentUser());
    }, [dispatch, refresh]);

    useEffect(() => {
        const fetchUser = async () => {
            const resultAction = await dispatch(fetchCurrentUser());
            if (fetchCurrentUser.fulfilled.match(resultAction)) {
                fetchMembershipDetails();
                setIsUserLoaded(true); // Set only if the user is successfully fetched
            }
        };
        fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const [searchParams] = useSearchParams();
    const restaurantName =
        searchParams.get("restaurantName")?.trim() || "Unknown Restaurant";
    const membershipType =
        searchParams.get("membershiptype")?.trim() || "Basic";

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function fetchMembershipDetails() {
        try {
            const response = await agent.Membership.getMembershipDetails(
                restaurantName,
                membershipType!
            );
            const restaurantResponse =
                await agent.Restaurant.getRestaurantByName(restaurantName);
            setMembership(response);
            setRestaurant(restaurantResponse);
        } catch (e) {
            console.error(e);
            alert("Problem occurred!");
            navigate(
                `/register?restaurantName=${restaurantName}&membershipType=${membershipType}`,
                { replace: true }
            );
        }
    }

    const handleJoin = async () => {
        const membership = {
            membershipType: "Basic",
            restaurantId: restaurant!.id,
        };

        setBtnLoading(true);
        try {
            let response = await agent.Membership.createMembership(membership);
            console.log(response);
            if (response?.message === "Membership successfully added.") {
                setMembership(response);
                console.log("response is set: ", response);
                console.log("benefit is set: ", response?.membership?.benefits);
                console.log("membership is set: ", membership);
                alert("Successfully joined the membership!");
            }
            setRefresh(!refresh);
        } catch (error) {
            console.error("Failed to join membership:", error);
            alert("An error occurred. Please try again.");
        } finally {
            setBtnLoading(false);
        }
    };
    const handleMembershipAction = async (
        actionType: "subscribe" | "unsubscribe" | "deactivate"
    ) => {
        const confirmationMessages: Record<typeof actionType, string> = {
            subscribe: "Are you sure you want to subscribe?",
            unsubscribe: "Are you sure you want to unsubscribe?",
            deactivate:
                "Are you sure you want to deactivate? Your membership won't be active again.",
        };

        const userConfirmed = window.confirm(confirmationMessages[actionType]);
        if (!userConfirmed) return;

        try {
            setBtnLoading(true);

            let response;
            switch (actionType) {
                case "subscribe":
                    response = await agent.Membership.subscribeMembership(
                        membership!.membership
                    );
                    if (
                        response?.message ===
                        "Subscribed to membership successfully."
                    ) {
                        setMembership({
                            ...membership!,
                            membership: {
                                ...membership!.membership,
                                isSubscribed: true,
                            },
                        });
                        alert("Subscribed to membership successfully.");
                    }
                    break;

                case "unsubscribe":
                    response = await agent.Membership.unsubscribeMembership(
                        membership!.membership
                    );
                    if (
                        response?.message ===
                        "Unsubscribed from membership successfully."
                    ) {
                        setMembership({
                            ...membership!,
                            membership: {
                                ...membership!.membership,
                                isSubscribed: false,
                            },
                        });
                        alert("Unsubscribed from membership successfully.");
                    }
                    break;

                case "deactivate":
                    response = await agent.Membership.deActivateMembership(
                        membership!.membership
                    );
                    if (
                        response?.message ===
                        "Membership deactivated successfully."
                    ) {
                        setMembership({
                            ...membership!,
                            membership: {
                                ...membership!.membership,
                                isActive: false,
                            },
                        });
                        alert(
                            "Your membership has been deactivated successfully."
                        );
                    }
                    break;

                default:
                    alert("Unknown action.");
            }

            if (!response?.message) {
                alert("Something went wrong. Please try again.");
            }
        } catch (error) {
            console.error(`Error during ${actionType}:`, error);
            alert(`Error during ${actionType}. Please try again.`);
        } finally {
            setBtnLoading(false);
        }
    };

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement>,
        index: number
    ): void => {
        setSelectedIndex(index);
    };

    if (JSON.parse(localStorage.getItem("user")!) === null)
        return (
            <Navigate
                to={`/register?restaurantName=${restaurantName}&membershipType=${membershipType}`}
                replace
            />
        );

    console.log("membership?.membership?.isSubscribed: ", membership);
    if (!isUserLoaded || !membership || !restaurant) {
        console.log("isUserLoaded: ", isUserLoaded);
        console.log("membership: ", membership);
        console.log("restaurant: ", restaurant);
        return <LoadingComponent message="Loading..." />;
    }

    return (
        <Card
            sx={{
                maxWidth: 450,
                margin: "auto",
                padding: "20px",
            }}
        >
            <Grid display="flex" mb="20px">
                <Grid
                    display="flex"
                    xs={3}
                    container
                    item
                    alignItems="center"
                    justifyContent="center"
                >
                    <img
                        src={`/images/${restaurantName}Icon.png`}
                        width="80%"
                        style={{ margin: "auto" }}
                        alt="logo"
                    />
                </Grid>
                <Grid
                    item
                    xs={9}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    ml="20px"
                >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                        {RestaurantNameDisplay(restaurant.restaurantName)}
                    </Typography>
                    <Link
                        color="inherit"
                        href={"tel:" + restaurant.phoneNumber}
                        underline="hover"
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                            }}
                        >
                            <PhoneIcon
                                fontSize="small"
                                sx={{
                                    marginRight: "5px",
                                    color: "#3f51b5",
                                }}
                            />
                            {displayPhoneNumber(restaurant.phoneNumber)}
                        </Typography>
                    </Link>
                    <Link
                        color="inherit"
                        href={
                            "https://www.google.com/maps/search/?api=1&query=" +
                            restaurant.restaurantName
                        }
                        target="_blank"
                        underline="hover"
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <PlaceIcon
                                fontSize="small"
                                sx={{
                                    marginRight: "5px",
                                    color: "#4caf50",
                                }}
                            />
                            {`${restaurant.address?.street}, 
                            ${restaurant.address?.city}`}
                        </Typography>
                    </Link>
                </Grid>
            </Grid>

            <Divider sx={{ mb: 2 }} />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid container spacing={2}>
                        <Grid xs={12} item sx={{ mb: 2 }}>
                            <VirtualMembershipCard
                                membership={membership.membership}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Chip
                                        icon={<CardMembershipIcon />}
                                        label="Membership Benefits"
                                        variant="outlined"
                                        color="primary"
                                        sx={{ padding: "5px" }}
                                    />
                                </Box>
                            </Grid>

                            <Box
                                sx={{
                                    width: "100%",
                                    maxWidth: 720,
                                    bgcolor: "background.paper",
                                }}
                            >
                                <List
                                    component="nav"
                                    aria-label="membership benefits"
                                >
                                    {membership.benefits.map(
                                        (benefit, index) => (
                                            <ListItemButton
                                                key={benefit.id}
                                                selected={
                                                    selectedIndex === index
                                                }
                                                onClick={(event) =>
                                                    handleListItemClick(
                                                        event,
                                                        index
                                                    )
                                                }
                                                sx={{
                                                    // Adjust margin or padding here
                                                    margin: 0, // You can set this to any value you like to reduce space between items
                                                    padding: 1, // Adjust vertical padding (optional, depending on your design)
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="h6"
                                                            fontWeight="bold"
                                                        >
                                                            {benefit.name}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        benefit.description && (
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                sx={{
                                                                    whiteSpace:
                                                                        "pre-wrap",
                                                                }}
                                                            >
                                                                {
                                                                    benefit.description
                                                                }
                                                            </Typography>
                                                        )
                                                    }
                                                />
                                            </ListItemButton>
                                        )
                                    )}
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sx={{ mt: "20px" }} item>
                        <Box sx={{ lineHeight: 0.5 }}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                align="left"
                            >
                                * Benefit details are subject to change and may
                                not always be available. Membership promotion
                                rules may vary by restaurant. Please check with
                                the restaurant for the most up-to-date
                                information.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} item>
                        {membership.membership == null ? (
                            // No membership
                            <LoadingButton
                                fullWidth
                                loading={btnLoading}
                                variant="contained"
                                onClick={handleJoin}
                                sx={{
                                    fontWeight: 700,
                                    mt: 3,
                                    fontSize: "1rem",
                                }}
                            >
                                {btnLoading ? "Joining..." : "Join Membership"}{" "}
                            </LoadingButton>
                        ) : !membership?.membership?.isActive ? (
                            // Membership is not active
                            <LoadingButton
                                disabled
                                fullWidth
                                loading={btnLoading}
                                variant="contained"
                                sx={{
                                    fontWeight: 700,
                                    mt: 3,
                                    fontSize: "1rem",
                                }}
                            >
                                Unable to rejoin once deactivated
                            </LoadingButton>
                        ) : (
                            // Membership is active
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    mt: 2,
                                }}
                            >
                                {membership?.membership?.isSubscribed ? (
                                    <LoadingButton
                                        loading={btnLoading}
                                        variant="outlined"
                                        color="warning"
                                        size="small"
                                        onClick={() =>
                                            handleMembershipAction(
                                                "unsubscribe"
                                            )
                                        }
                                        sx={{
                                            mt: 3,
                                            flex: 1,
                                            mr: 2,
                                        }}
                                    >
                                        {btnLoading
                                            ? "Unsubscribing..."
                                            : "Unsubscribe Membership"}
                                    </LoadingButton>
                                ) : (
                                    <LoadingButton
                                        loading={btnLoading}
                                        variant="outlined"
                                        color="success"
                                        size="small"
                                        onClick={() =>
                                            handleMembershipAction("subscribe")
                                        }
                                        sx={{
                                            mt: 3,
                                            flex: 1,
                                            mr: 2,
                                        }}
                                    >
                                        {btnLoading
                                            ? "Subscribing..."
                                            : "Subscribe Membership"}
                                    </LoadingButton>
                                )}

                                <LoadingButton
                                    loading={btnLoading}
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                        handleMembershipAction("deactivate")
                                    }
                                    sx={{
                                        mt: 3,
                                        flex: 1,
                                    }}
                                >
                                    {btnLoading
                                        ? "Deactivating..."
                                        : "Deactivate Membership"}
                                </LoadingButton>
                            </Box>
                        )}
                    </Grid>
                    {/* We don't want to have this button to go back to the membership page now because user should scan QR code at a restaurant, they shouldn't register membership at home as of 2024 Dec 31.
                    <Grid xs={12} sx={{ mt: "20px" }} item>
                        <Button fullWidth variant="outlined" color="primary">
                            Check all available memberships
                        </Button>
                    </Grid> */}
                </Grid>
            </CardContent>
        </Card>
    );
}
