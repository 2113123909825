import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useEffect, useState } from "react";
import { fetchCurrentUser } from "../account/accountSlice";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Navigate, useSearchParams } from "react-router-dom";
import MembershipCardPage from "../guest/Membership/MembershipCard";
import RestaurantCards from "./RestaurantCards";

export default function MainPage() {
    const { user } = useAppSelector((state) => state.account);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchUser = async () => {
            const resultAction = await dispatch(fetchCurrentUser());
            if (fetchCurrentUser.fulfilled.match(resultAction)) {
                setIsUserLoaded(true); // Set only if the user is successfully fetched
            }
        };
        fetchUser();
    }, [dispatch]);
    const [isUserLoaded, setIsUserLoaded] = useState(false);

    const [searchParams] = useSearchParams();
    const restaurantName =
        searchParams.get("restaurantName") || "Unknown Restaurant";
    const membershipType = searchParams.get("membershiptype") || "Basic";

    if (JSON.parse(localStorage.getItem("user")!) === null)
        return (
            <Navigate
                to={`/register?restaurantName=${restaurantName}&membershipType=${membershipType}`}
                replace
            />
        );
        
    if (!isUserLoaded) return <LoadingComponent message="Loading..." />;

    return (
        <>
            {user!.roles?.includes("Admin") && <RestaurantCards />}
            {!user!.roles?.includes("Admin") &&
                user!.roles?.includes("Member") &&
                (restaurantName === "Unknown Restaurant" ? (
                    <MembershipCardPage />
                ) : (
                    <Navigate
                        to={`/membership?restaurantName=${restaurantName}&membershipType=${membershipType}`}
                        replace
                    />
                ))}
        </>
    );
}
