import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import agent from "../../../app/api/agent";

export default function MembershipIdQRScan() {
    const [searchParams] = useSearchParams();
    const membershipId = searchParams.get("membershipId");


    useEffect(() => {
        const checkMembership = async () => {
            console.log("Checking membership:", membershipId);
            if (membershipId) {
                try {
                    const response = await agent.Membership.getMembershipById(membershipId);
                    console.log("Response:", response);
                    alert(response.message);
                } catch (error) {
                    console.error("Error:", error);
                    alert("Membership not found.");
                }
            }
        };
    
        checkMembership();
    }, [membershipId]);

    return (
        <></>
    );
}
