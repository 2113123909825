import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Container, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import agent from "../../../app/api/agent";
import { QueueSizeObj } from "../../../app/models/stat";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    plugins: {
        title: {
            display: true,
            text: "Weekly Waitlist Report",
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};


export default function SizeStat() {
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const currentDate = new Date();
        const lastWeekDate = new Date(currentDate);
        lastWeekDate.setDate(currentDate.getDate() - 7);
        const currentDateString = currentDate.toISOString().split("T")[0];
        const lastWeekDateString = lastWeekDate.toISOString().split("T")[0];

        const lastweek = {
            datetime: lastWeekDateString,
            restaurantName: restaurantParam,
        };

        const thisweek = {
            datetime: currentDateString,
            restaurantName: restaurantParam,
        };

        var newLabels: string[] = [];
        var newSizeCount: number[] = [];
        var newSizeKidsCount: number[] = [];

        agent.Statistics.getWeeklyQueuesSize(lastweek)
            .then((queues: QueueSizeObj[]) => {
                queues.map((queue: QueueSizeObj) => {
                    const countDate = queue.date.toString().split("T")[0];
                    const countDay = new Date(queue.date).toLocaleString(
                        "en-us",
                        { weekday: "long" }
                    );
                    newLabels.push(`${countDate} (${countDay})`);
                    newSizeCount.push(queue.sizeCount);
                    newSizeKidsCount.push(queue.sizeKidsCount);
                });
            })
            .then(() => {
                setData({
                    labels: newLabels,
                    datasets: [
                        {
                            label: "# of Adults",
                            data: newSizeCount,
                            backgroundColor: "rgb(255, 99, 132)",
                        },
                        {
                            label: "# of Kids",
                            data: newSizeKidsCount,
                            backgroundColor: "rgb(75, 192, 192)",
                        },
                    ],
                });
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <Container sx={{ height: "100%" }}>
            {loading ? (
                <>
                    <Skeleton
                        variant="text"
                        width="20%"
                        sx={{
                            fontSize: "1rem",
                            display: "block",
                            margin: "auto",
                        }}
                    />
                    <Skeleton
                        variant="text"
                        width="40%"
                        sx={{
                            fontSize: "1rem",
                            display: "block",
                            margin: "auto",
                        }}
                    />
                    <br />
                    <Skeleton
                        variant="rounded"
                        width="90%"
                        height={500}
                        sx={{ display: "block", margin: "auto" }}
                    />
                </>
            ) : (
                <Bar options={options} data={data} />
            )}
        </Container>
    );
}
