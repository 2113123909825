import { useAppDispatch } from "../../../app/store/configureStore";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Container,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { useForm, FieldValues } from "react-hook-form";
import { primaryColor } from "../../../app/style/customColor";
import PasswordIcon from "@mui/icons-material/Password";
import { requestResetPasswordMember } from "../../account/accountSlice";

const PasswordResetFormPublic = () => {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        mode: "onTouched",
    });
    async function submitForm(data: FieldValues) {
        try {
            await dispatch(requestResetPasswordMember(data));
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    return (
        <Container component={Paper} maxWidth="xs" sx={{ p: 3 }}>
            <Grid container>
                <PasswordIcon fontSize="large" color="primary" />
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={primaryColor}
                    sx={{ marginLeft: 1 }}
                >
                    Password Reset
                </Typography>
            </Grid>
            <Divider />
            <Box
                component="form"
                onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{ mt: 3 }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="User Name"
                            variant="standard"
                            {...register("userName", {
                                required: "User name is required",
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Email Address"
                            autoComplete="email"
                            variant="standard"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
                                    message: "Not a valid email address",
                                },
                            })}
                            error={!!errors.email}
                            helperText={errors?.email?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            disabled={!isValid}
                            loading={isSubmitting}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Send Password Reset Email
                        </LoadingButton>
                    </Grid>
                </Grid>
                <div style={{ height: "0px", overflow: "hidden" }}>
                    <TextField
                        hidden={true}
                        value={window.location.href}
                        {...register("sourceUrl")}
                    />
                </div>
            </Box>
            <Typography variant="body2">
                * A password reset link will be sent to your email address
            </Typography>
        </Container>
    );
};

export default PasswordResetFormPublic;
