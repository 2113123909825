import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { brandName } from "../configuration/globalConfig";

export default function StickyFooter() {
    const queryParams = new URLSearchParams(window.location.search);
    const rotate = queryParams.has("rotate");

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                zIndex: 99,
                display: rotate ? "none" : "flex", //TODO: Fix this to be dynamic
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                component="footer"
                sx={{
                    backgroundColor: "whitesmoke",
                    padding: "3px",
                    width: "100%",
                }}
                textAlign="center"
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                    >
                        &nbsp;&nbsp;{"Copyright © 2023 - "}
                        {new Date().getFullYear() + ", "}
                    </Typography>

                    <Typography
                        variant="caption"
                        fontWeight={700}
                    >&nbsp;&nbsp;
                        <Link
                            href="https://www.queuingme.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {brandName}
                        </Link>
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
}
