import QRCode from "react-qr-code";
import { Card, Typography, Divider } from "@mui/material";
import { Membership } from "../../../app/models/membership";
import { getFullLocaleTime } from "../../../app/extentions/WaitingTime";

interface Props {
    membership: Membership;
}

const VirtualMembershipCard = ({ membership }: Props) => {
    if (!membership) return null;
    
    const membershipUrl = `${process.env.REACT_APP_BASE_URL}/checkMembership?membershipId=${membership.id}`;

    return (
        <Card
            sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f5f5f5",
                textAlign: "center",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontWeight: "bold",
                    color: "#333",
                    mb: 1,
                }}
            >
                {membership.userName}
            </Typography>

            <Divider sx={{ width: "100%", my: 1 }} />

            <QRCode value={membershipUrl} size={150} />

            <Typography
                variant="body2"
                sx={{
                    mt: 2,
                    color: "#555",
                }}
            >
                {membership.userEmail}
            </Typography>

            <Typography
                variant="subtitle2"
                sx={{
                    color: membership.isActive ? "green" : "red",
                    fontWeight: "bold",
                    mt: 1,
                }}
            >
                {membership.isActive ? "Active" : "Inactive"}
            </Typography>

            <Divider sx={{ width: "100%", my: 1 }} />

            <Typography
                variant="body2"
                sx={{
                    color: "#777",
                }}
            >
                {membership.membershipType} Membership
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    color: "#777",
                }}
            >
                {membership.isSubscribed ? "Subscribed" : "Not Subscribed"}
            </Typography>
            <Typography
                variant="caption"
                sx={{
                    color: "#777",
                    mt: 1,
                }}
            >
                Created on {getFullLocaleTime(membership.createdTime)}
            </Typography>
        </Card>
    );
};

export default VirtualMembershipCard;
