import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch } from "../../app/store/configureStore";
import { signInUser } from "./accountSlice";
import { LoadingButton } from "@mui/lab";

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        mode: "onTouched",
    });

    const [searchParams] = useSearchParams();
    const restaurantName =
        searchParams.get("restaurantName") || "Unknown Restaurant";
    const membershipType = searchParams.get("membershiptype") || "Basic";

    async function submitForm(data: FieldValues) {
        try {
            await dispatch(signInUser(data)).then((res) => {
                if (res.type === "account/signInUser/rejected") return;

                if (restaurantName === "Unknown Restaurant") {
                    navigate("/main");
                } else {
                    navigate(
                        `/membership?restaurantName=${encodeURIComponent(
                            restaurantName
                        )}&membershipType=${encodeURIComponent(membershipType)}`
                    );
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container
            component={Paper}
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto",
                mt: 1,
                mb: 4,
                p: 2,
                boxShadow: 10,
                width: "95%",
            }}
        >
            <img
                src={`/images/QueuingMeLogo.png`}
                width="100px"
                style={{ margin: "auto" }}
                alt="logo"
            />
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h6">
                Sign in
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="User Name"
                    autoComplete="username"
                    autoFocus
                    {...register("username", {
                        required: "User name is required",
                    })}
                    error={!!errors.username}
                    helperText={errors?.username?.message as string}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    {...register("password", {
                        required: "Password is required",
                    })}
                    error={!!errors.password}
                    helperText={errors?.password?.message as string}
                />
                <Grid container justifyContent="flex-end" sx={{ mb: 1 }}>
                    <Link
                        to="/request-password"
                        style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            padding: 4,
                        }}
                    >
                        <Typography fontSize="12px" color="primary">
                            Forgot your password?
                        </Typography>
                    </Link>
                </Grid>
                <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                >
                    Sign In
                </LoadingButton>
                <Grid container justifyContent="center" alignItems="center" mt={4}>
                    <Grid item>
                        <Typography
                            fontSize={16}
                            textAlign="center"
                            sx={{
                                display: "inline-flex",
                                alignItems: "center",
                            }}
                        >
                            Don't have an account?
                            <Link
                                to={`/register`}
                                style={{
                                    padding: 4,
                                    fontWeight: 500,
                                    fontSize: 18,
                                }}
                            >
                                Sign Up
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
