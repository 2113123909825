import { useNavigate, useParams } from "react-router-dom";
import agent from "../../../app/api/agent";
import { Queue, Status } from "../../../app/models/queue";
import { useState, useEffect } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Tooltip,
    Typography,
} from "@mui/material";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import InfoIcon from "@mui/icons-material/Info";
import { alertCancelMessage } from "../../../app/configuration/alert";
import { successColor } from "../../../app/style/customColor";
import {
    BBQrestaurants,
    restaurant,
} from "../../../app/configuration/restaurant";
import NotFound from "../../errors/NotFound";
import { displayPhoneNumber } from "../../../app/configuration/phoneNumberConvertor";
import { guestMsg } from "../../../app/configuration/guestMsg";
import { getExpectedWaitingTime } from "../../../app/extentions/WaitingTime";
import {
    getCapitalizedName,
    getInitial,
    getPositionString,
    publicRoutes,
} from "../../../app/configuration/globalConfig";
import GuestQueueDetailsHeader from "./GuestQueueDetailsHeader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import { Bubble } from "../../../app/layout/partComponents/Bubble";
import DemoDialog from "../../../app/layout/partComponents/DemoDialog";

export default function GuestQueueDetailsPage() {
    const { id, restaurantParam } = useParams<{
        id: string;
        restaurantParam: string;
    }>();
    const [queue, setQueue] = useState<Queue>();
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const navigate = useNavigate();

    function refreshPage() {
        setRefresh(!refresh);
    }

    async function cancelQueue() {
        const confirmCancel = window.confirm(
            "Are you sure you want to leave the queue?"
        );
        if (!confirmCancel) {
            return;
        }

        let currentStatus = Status.Waiting;
        await agent.Queue.details(id!, restaurantParam!).then((res) => {
            currentStatus = res!.status;
            if (currentStatus === Status.NoShow) {
                alert("It's marked as NoShow. Please line up again.");
                return;
            }
            if (currentStatus === Status.Complete) {
                alert("It's completed queue. Please line up again.");
                return;
            }
            agent.Queue.updateStatusByGuest({
                id: id,
                status: Status.Cancel,
                restaurantName: restaurantParam,
            }).then((res) => {
                if (res.id === id) {
                    let msg = alertCancelMessage(currentStatus);
                    alert(msg);
                    navigate("/");
                }
            });
        });
    }

    useEffect(() => {
        agent.Queue.details(id!, restaurantParam!)
            .then((res) => {
                res.id = id;
                setQueue(res);
                setLoading(false);
            })
            .catch((e) => {
                console.error(e.response);
                alert("Problem occured!");
                navigate(`/${restaurantParam}/${publicRoutes}/guest`);
            });
    }, [refresh]); //eslint-disable-line

    if (loading) return <LoadingComponent message="Loading..." />;

    if (!queue) return <NotFound />;

    if (!restaurant.includes(restaurantParam!.toLowerCase()))
        return <NotFound />;

    return (
        <div style={{}}>
            <Card
                style={{
                    maxWidth: 450,
                    margin: "auto",
                    padding: "5px",
                }}
                sx={{ boxShadow: 10 }}
            >
                <GuestQueueDetailsHeader
                    restaurant={queue!.restaurant}
                    restaurantParam={restaurantParam!}
                />

                <Grid
                    container
                    spacing={1}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "40px 0",
                    }}
                >
                    {queue!.status === Status.Waiting ||
                    queue!.status === Status.Notified ||
                    queue!.status === Status.Ready ? (
                        <Bubble message={getPositionString(queue!.position!)} />
                    ) : (
                        <>
                            <Bubble message="Thanks!" />
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                sx={{ m: "20px 0" }}
                            >
                                How was your experience with QueuingMe?
                            </Typography>
                            <DemoDialog />
                        </>
                    )}
                </Grid>
                <Accordion
                    defaultExpanded
                    sx={{
                        boxShadow: "none",
                        border: "none",
                        "&:before": {
                            display: "none",
                        },
                        mt: "50px",
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Avatar
                            sx={{
                                bgcolor: successColor,
                                fontWeight: "bold",
                                marginRight: "10px",
                            }}
                        >
                            {getInitial(queue!.userName)}
                        </Avatar>
                        <Grid container>
                            <Grid
                                item
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: "100%",
                                }}
                            >
                                <>
                                    <Typography
                                        variant="body1"
                                        fontWeight={500}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {getCapitalizedName(
                                                queue!.userName
                                            )}
                                            <PersonIcon
                                                sx={{
                                                    fontSize: "large",
                                                    marginLeft: "4px",
                                                    paddingBottom: "1px"
                                                }}
                                            />
                                                {queue!.size + queue!.sizeKids}
                                        </Box>
                                    </Typography>
                                </>
                                <Typography variant="caption" color="grey">
                                    {new Date(
                                        queue!.createdTime
                                    ).toLocaleString()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid xs={4} item>
                                    <Typography color="grey" variant="body2">
                                        Phone #
                                    </Typography>
                                </Grid>
                                <Grid xs={8} item>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {displayPhoneNumber(queue!.phoneNumber)}
                                    </Typography>
                                </Grid>
                                <Grid xs={4} item>
                                    <Typography color="grey" variant="body2">
                                        Party Size
                                    </Typography>
                                </Grid>
                                <Grid xs={8} item>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {queue!.size + " + " + queue!.sizeKids}
                                    </Typography>
                                </Grid>
                                {[Status.Waiting, Status.Notified].includes(
                                    queue!.status
                                ) && (
                                    <>
                                        <Grid xs={4} item>
                                            <Typography
                                                color="grey"
                                                variant="body2"
                                            >
                                                Expected Wait
                                            </Typography>
                                        </Grid>
                                        <Grid xs={8} item>
                                            <Box
                                                display="flex"
                                                alignContent="center"
                                            >
                                                <Typography
                                                    fontWeight="bold"
                                                    variant="body2"
                                                >
                                                    {getExpectedWaitingTime(
                                                        queue!.position!
                                                    )}
                                                </Typography>
                                                <Tooltip
                                                    title={
                                                        <>
                                                            {
                                                                guestMsg
                                                                    .GuestDetailMessage
                                                                    .expectedWaitTime
                                                            }
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        sx={{
                                                            fontSize: "16px",
                                                            color: "grey",
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                <Grid xs={4} item>
                                    <Typography color="grey" variant="body2">
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid xs={8} item alignContent="center">
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {Status[queue!.status]}
                                    </Typography>
                                </Grid>
                                {BBQrestaurants.includes(
                                    restaurantParam!.toLowerCase()
                                ) && (
                                    <>
                                        <Grid xs={4} item>
                                            <Typography
                                                color="grey"
                                                variant="body2"
                                            >
                                                BBQ
                                            </Typography>
                                        </Grid>
                                        <Grid xs={8} item alignContent="center">
                                            <Typography
                                                fontWeight="bold"
                                                variant="body2"
                                            >
                                                {queue!.isBbq ? "Yes" : "No"}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                {queue?.note && queue.note.trim() !== "" && (
                                    <>
                                        <Grid xs={4} item>
                                            <Typography
                                                color="grey"
                                                variant="body2"
                                            >
                                                Note
                                            </Typography>
                                        </Grid>
                                        <Grid xs={8} item>
                                            <Typography
                                                fontWeight="bold"
                                                variant="body2"
                                            >
                                                {queue!.note}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
                {!(
                    queue!.status === Status.Cancel ||
                    queue!.status === Status.Complete ||
                    queue!.status === Status.NoShow
                ) && (
                    <Grid container spacing={2} p={1}>
                        <Grid xs={5} item>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="error"
                                onClick={cancelQueue}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid xs={7} item>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={refreshPage}
                            >
                                Refresh
                                <RefreshSharpIcon />
                            </Button>
                        </Grid>
                        <Grid xs={12} sx={{ mt: "10px", mb: "10px" }} item>
                            <Typography fontSize={12} color="grey">
                                {
                                    guestMsg.GuestDetailMessage.content
                                        .footerEnglish
                                }{" "}
                                <br />
                                {queue!.restaurant.restaurantOption
                                    .menuImageUrl &&
                                    guestMsg.GuestDetailMessage.menuPriceAlert}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Card>
        </div>
    );
}
