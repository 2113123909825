import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    CircularProgress,
    IconButton,
    Grid,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";

interface MenuUploadDialogProps {
    open: boolean;
    onClose: () => void;
    currentMenuUrl: string;
    onUpload: (file: File) => Promise<void>;
}

export default function MenuUploadDialog({
    open,
    onClose,
    currentMenuUrl,
    onUpload,
}: MenuUploadDialogProps) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setSelectedFile(acceptedFiles[0]);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        // accept: {
        //     "image/*": [".jpg", ".jpeg", ".png", ".gif", ".webp"],
        //     "application/pdf": [".pdf"],
        // },
        maxSize: 15 * 1024 * 1024, // 15MB
    });

    const handleUpload = async () => {
        if (selectedFile) {
            setIsUploading(true);
            try {
                await onUpload(selectedFile); // 이 부분에서 `selectedFile`이 잘 전달되고 있는지
                console.log(currentMenuUrl);
                setSelectedFile(null); // Reset selected file after upload
            } catch (error) {
                console.error("Upload failed:", error);
            } finally {
                setIsUploading(false);
            }
        } else {
            console.log("No file selected");
        }
    };
    
    

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                Upload Menu Image
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={4}>
                        <Typography variant="subtitle1" gutterBottom>
                            Current Menu Preview:
                        </Typography>
                        {currentMenuUrl !== "" ? (
                            <Box
                                component="img"
                                src={currentMenuUrl}
                                alt="Current Menu"
                                sx={{
                                    width: "100%",
                                    maxHeight: "300px",
                                    objectFit: "contain",
                                    borderRadius: 2,
                                    mb: 2,
                                }}
                            />
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                No menu image available.
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            Upload New Menu:
                        </Typography>
                        <Box
                            {...getRootProps()}
                            sx={{
                                border: "2px dashed #ccc",
                                padding: "20px",
                                textAlign: "center",
                                borderRadius: 2,
                                cursor: "pointer",
                                backgroundColor: "#f9f9f9",
                                "&:hover": { backgroundColor: "#f0f0f0" },
                            }}
                        >
                            <input {...getInputProps()} />
                            <Typography variant="body2">
                                Drag and drop a file here, or click to select
                                one.
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                (Only images up to 15MB are supported.)
                            </Typography>
                        </Box>
                        {selectedFile && (
                            <Box mt={2}>
                                <Typography variant="body2">
                                    Selected File: {selectedFile.name}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleUpload}
                    color="primary"
                    disabled={!selectedFile || isUploading}
                    startIcon={isUploading && <CircularProgress size={20} />}
                >
                    {isUploading ? "Uploading..." : "Upload"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
