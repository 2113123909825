import React from "react";
import { Typography, Box } from "@mui/material";

const TermsOfServiceRegistration: React.FC = () => {
    const date = new Date("2024-12-22");
    const formattedDate = `${
        date.getMonth() + 1
    } ${date.getDate()}, ${date.getFullYear()}`;

    return (
        <Box mt={2} mb={4}>
            <Typography variant="body2" gutterBottom>
                Last updated: {formattedDate}
            </Typography>
            <Typography variant="body1" paragraph>
                Welcome to QueuingMe! These Terms of Service ("Terms") govern
                your use of the QueuingMe membership registration service. By
                accessing or using the service, you agree to these Terms.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Membership Registration
            </Typography>
            <Typography variant="body1" paragraph>
                By registering for a participating restaurant's membership, you
                consent to provide personal information such as your name,
                phone number, and email address to QueuingMe and the
                restaurant. This information will be used to manage membership
                benefits, provide promotional updates, and send notifications.
            </Typography>

            <Typography variant="h6" gutterBottom>
                2. Communications and Promotions
            </Typography>
            <Typography variant="body1" paragraph>
                By registering, you agree to receive membership updates,
                promotions, and notifications about benefits from the
                restaurant and QueuingMe via email and SMS. You can opt out of
                promotional messages at any time.
            </Typography>

            <Typography variant="h6" gutterBottom>
                3. Data Privacy
            </Typography>
            <Typography variant="body1" paragraph>
                QueuingMe complies with the British Columbia Personal
                Information Protection Act (PIPA). Your personal information
                will only be used for membership management and promotional
                updates as outlined in these Terms. For more details, please
                refer to our Privacy Policy.
            </Typography>

            <Typography variant="h6" gutterBottom>
                4. Membership Benefits
            </Typography>
            <Typography variant="body1" paragraph>
                Membership benefits and promotions are determined by the
                participating restaurant. QueuingMe is not responsible for the
                accuracy or fulfillment of these benefits. For inquiries,
                please contact the restaurant directly.
            </Typography>

            <Typography variant="h6" gutterBottom>
                5. Termination
            </Typography>
            <Typography variant="body1" paragraph>
                Your membership may be terminated by the restaurant or
                QueuingMe if you violate the Terms or if the membership program
                is discontinued. You may also cancel your membership at any
                time by contacting the restaurant.
            </Typography>

            <Typography variant="h6" gutterBottom>
                6. Limitation of Liability
            </Typography>
            <Typography variant="body1" paragraph>
                QueuingMe is not liable for disputes, errors, or damages arising
                from the restaurant's membership program or related promotions.
            </Typography>

            <Typography variant="h6" gutterBottom>
                7. Changes to Terms
            </Typography>
            <Typography variant="body1" paragraph>
                These Terms may be updated periodically. Your continued use of
                the membership program signifies your acceptance of the changes.
                The latest Terms will always be available on the registration
                page.
            </Typography>

            <Typography variant="h6" gutterBottom>
                8. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                If you have questions or concerns about these Terms, please
                contact us at&nbsp;
                <a href="mailto:queuingme.business@gmail.com">
                    queuingme.business@gmail.com
                </a>.
            </Typography>
        </Box>
    );
};

export default TermsOfServiceRegistration;
