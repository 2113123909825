import {
    Container,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import React from "react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { displayPhoneNumber } from "../../app/configuration/phoneNumberConvertor";
import RestaurantNameDisplay from "../../app/configuration/restaurantNameDisplay";

export default function RestaurantCards() {
    const { user } = useAppSelector((state) => state.account);

    if (!user) return <LoadingComponent message="Loading..." />;

    return (
        <>
            {user!.roles?.includes("Admin") && (
                <List
                    sx={{
                        width: "95%",
                        maxWidth: "600px",
                        bgcolor: "background.paper",
                        display: "block",
                        margin: "auto",
                        boxShadow: 10,
                        padding: 0,
                    }}
                >
                    {user!.restaurants?.length === 0 && (
                        <ListItem
                            alignItems="flex-start"
                            sx={{
                                backgroundColor: "darkred",
                                marginTop: "3rem",
                                textAlign: "center",
                            }}
                        >
                            <ListItemText
                                primary={
                                    "No Restaurants is registered to this account"
                                }
                                sx={{
                                    color: "white",
                                    fontWeight: "900",
                                }}
                            ></ListItemText>
                        </ListItem>
                    )}
                    <Container
                        sx={{
                            marginTop: "3rem",
                        }}
                    >
                        {user!.restaurants?.map((res, idx) => {
                            return (
                                <a
                                    href={res.restaurantName}
                                    style={{ textDecoration: "none" }}
                                    key={idx}
                                >
                                    <ListItem alignItems="center">
                                        <ListItemAvatar>
                                            <img
                                                alt={res.restaurantName}
                                                src={
                                                    "images/" +
                                                    res.restaurantName +
                                                    "Icon.png"
                                                }
                                                width="80px"
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                >
                                                    {RestaurantNameDisplay(
                                                        res.restaurantName
                                                    )}
                                                </Typography>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    {displayPhoneNumber(
                                                        res.phoneNumber
                                                    )}
                                                    <br />
                                                    {res.restaurantUrl}
                                                </React.Fragment>
                                            }
                                            sx={{ marginLeft: "20px" }}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </a>
                            );
                        })}
                    </Container>
                </List>
            )}
        </>
    );
}
