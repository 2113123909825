import { Box, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

export function Bubble({ message }: { message: string }) {
    return (
        <Box
            sx={{
                position: "relative",
                maxWidth: "200px",
                padding: "20px",
                backgroundColor: "#fefefe",
                color: "",
                borderRadius: "20px",
                fontSize: "14px",
                boxShadow: 2,
                display: "inline-block",
            }}
        >
            <Typography variant="h4">
                <span style={{ color: blue[500], fontWeight: 700 }}>
                    {message.split(" ")[0]}
                </span>
                <span style={{ fontSize: "20px" }}>
                    {" "}
                    {message.slice(message.split(" ")[0].length)}{" "}
                </span>
            </Typography>
            {/* 
            <Box
                sx={{
                    position: "absolute",
                    bottom: "-8px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: 0,
                    height: 0,
                    borderLeft: "8px solid transparent",
                    borderRight: "8px solid transparent",
                    borderTop: "8px solid #fefefe",
                }}
            /> */}
        </Box>
    );
}
