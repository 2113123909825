export function formatName(firstName: string | null, lastName: string | null) {
    const isKorean = /[\uAC00-\uD7AF]/.test((firstName || "") + (lastName || "")); // Check if Korean

    if (isKorean) {
        return `${lastName || ""}${firstName || ""}`;
    }

    const formattedFirstName = firstName ? capitalizeFirstLetter(firstName) : "";

    if (!lastName) {
        return formattedFirstName;
    }

    return `${formattedFirstName} ${lastName.charAt(0).toUpperCase()}.`;
}

export function capitalizeFirstLetter(text: string) {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
}
