import { useEffect } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    Box,
    Typography,
    Grid,
    Chip,
    Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../app/layout/LoadingComponent";
import {
    adminTableFontSize,
    StyledTableCell,
    StyledTableRow,
} from "../../app/style/customStyle";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import {
    getAllMembershipAsync,
    membershipSelectors,
    setPageNumber,
} from "../membership/membershipSlice";
import MembershipSearch from "./MembershipSearch";
import AppPagination from "../../app/components/AppPagination";
import { formatName } from "../../app/extentions/formatter";

const MembershipPage = () => {
    const memberships = useAppSelector(membershipSelectors.selectAll);
    const { membershipLoaded, membershipParams, status, metaData } =
        useAppSelector((state) => state.membership);
    const dispatch = useAppDispatch();
    const { restaurantParam } = useParams<{ restaurantParam: string }>();

    useEffect(() => {
        const fetchMemberships = async () => {
            if (!membershipLoaded && restaurantParam) {
                await dispatch(getAllMembershipAsync(restaurantParam));
            }
        };

        fetchMemberships();
    }, [membershipLoaded, dispatch, restaurantParam, membershipParams]);

    if (status.includes("pending"))
        return <LoadingComponent message="Loading..." />;

    return (
        <Container maxWidth="md">
            <Grid container component={Paper} sx={{ borderRadius: "4px 4px 0 0" }}>
                <Grid item xs={12} sm={4}>
                    <MembershipSearch />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper
                        sx={{
                            p: "2px 4px",
                            borderRadius: 0,
                            boxShadow: "none",
                        }}
                    >
                        {metaData && (
                            <AppPagination
                                metaData={metaData}
                                onPageChange={(page: number) =>
                                    dispatch(
                                        setPageNumber({ pageNumber: page })
                                    )
                                }
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                {/* Display membership data */}
                <TableContainer
                    component={Paper}
                    sx={{ borderRadius: "0 0 4px 4px" }}
                >
                    <Table size="small" aria-label="a simple table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    fontSize: adminTableFontSize,
                                    fontWeight: "bold",
                                }}
                            >
                                <StyledTableCell>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "bold", pl: 2 }}
                                    >
                                        ID / Customer Name
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ pl: 4 }}
                                    >
                                        email
                                    </Typography>
                                </StyledTableCell>
                                {/* <StyledTableCell align="center">
                                    Point
                                </StyledTableCell> */}
                                <StyledTableCell
                                    align="center"
                                    sx={{ width: "100px" }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Created Date
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ width: "100px" }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Subscription
                                    </Typography>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {memberships.map((membership, idx) => (
                                <StyledTableRow key={idx}>
                                    <StyledTableCell sx={{ background: `linear-gradient(to right, ${membership.isSubscribed ? "#4caf50" : "#f44336"} 10px, transparent 10px)`}}>
                                        <Box
                                            sx={{
                                                maxHeight: "103px",
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: "bold",
                                                    pl: 2,
                                                }}
                                            >
                                                {formatName(
                                                    membership.userFirstName,
                                                    membership.userLastName
                                                )}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                sx={{
                                                    pl: 4,
                                                    // fontFamily: '"Courier New", Courier, monospace',
                                                    // whiteSpace: "nowrap", // Prevent wrapping for long GUIDs
                                                }}
                                            >
                                                {membership.userName} -{" "}
                                                {membership.userEmail}
                                            </Typography>
                                        </Box>
                                    </StyledTableCell>
                                    {/* <StyledTableCell align="center">
                                        <Button
                                            variant={membership.points > 0 ? "contained" : "outlined"}
                                            color="info"
                                            size="small"
                                            sx={{ borderRadius: 50, padding: 0 }}
                                        >
                                            {membership.points}
                                        </Button>
                                    </StyledTableCell> */}
                                    <StyledTableCell align="center">
                                        {new Date(
                                            membership.createdTime
                                        ).toLocaleDateString("en-US", {
                                            month: "short", // Short month name (e.g., Jan, Feb)
                                            day: "2-digit", // Two-digit day (e.g., 05)
                                            year: "numeric", // Full year (e.g., 2024)
                                        })}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {membership.isSubscribed ? (
                                            <Chip
                                                label="Subscribed"
                                                color="success"
                                                variant="filled"
                                                size="small"
                                            />
                                        ) : (
                                            <Chip
                                                label="Unsubscribed"
                                                color="error"
                                                variant="filled"
                                                size="small"
                                            />
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Container>
    );
};

export default MembershipPage;
