import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import ContactPage from '../HomePage/ContactPage';
import { Box, Typography } from '@mui/material';

export default function DemoDialog() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button variant="contained" onClick={handleClickOpen}>
        Request Demo
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            marginLeft: 0,
            marginRight: 0,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"QueuingMe Demo Request"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            <span style={{fontWeight: 800}}>QueuingMe</span> is the best way to manage your restaurant's queue. Request a demo to see how it works.
            </Typography>
            <ContactPage />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancle
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
