import { Alert } from "@mui/material";

export default function WaitingTimeCalculator(datetime: string) {
    var now = new Date().getTime();
    var date = new Date(datetime).getTime();
    var diffTime = Math.round((now - date) / 60000);
    if (diffTime > 60) {
        return (
            <Alert severity="error">
                <b>{datetime.split("T")[1].split(".")[0].slice(0, 5)}</b>{" "}
                Checked in, <b>{diffTime} minutes</b> waiting
            </Alert>
        );
    } else if (diffTime > 30) {
        return (
            <Alert severity="warning">
                <b>{datetime.split("T")[1].split(".")[0].slice(0, 5)}</b>{" "}
                Checked in, <b>{diffTime} minutes</b> waiting
            </Alert>
        );
    } else {
        return (
            <Alert severity="info">
                <b>{datetime.split("T")[1].split(".")[0].slice(0, 5)}</b>{" "}
                Checked in, <b>{diffTime} minutes</b> waiting
            </Alert>
        );
    }
}

export function GetCheckinTime(datetime: string) {
    return datetime.split("T")[1].split(".")[0].slice(0, 5);
}

export function GetWaitingTime(datetime: string) {
    var now = new Date().getTime();
    var date = new Date(datetime).getTime();
    return Math.round((now - date) / 60000);
}

export function ReadyTimeCalculator(datetime: string) {
    var now = new Date().getTime();
    var date = new Date(datetime).getTime();
    var diffTime = Math.round((now - date) / 60000);
    return diffTime;
}

export function diff_minutes(dt2: string, dt1: string) {
    var diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

export function getLocaleTime(datetime: string) {
    return new Date(datetime).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
}

export function getFullLocaleTime(datetime: string) {
    // Parse the datetime string into a Date object in UTC
    const utcDate = new Date(datetime + "Z"); // "Z" ensures that it's treated as UTC
    return utcDate.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
    });
}

export function getExpectedWaitingTime(q: number) {
    var wait = (q - 1) * 5;
    if (wait < 0) wait = 0;
    return q < 10
        ? `${wait} minutes ~ ${wait + 15} minutes`
        : "40 minutes +";
}

export function formatDateYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}